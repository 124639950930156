import React, { useEffect, useState } from "react";
import $ from "jquery";
import validator from "validator";
import CustomHeader from "../../common/CustomHeader";
import { noticeIcon } from "../../../assets/media";
import { getBanks } from "../../../utils/DataController";
import ListPicker from "../ListPicker";
import { useNavigate } from "react-router-dom";
import GradientButton from "../GradientButton";
import { addUserBank } from "../../../utils/UserController";
import Toast from "../Toast";

const AddBank = () => {
  const [banks, setBanks] = useState([]);
  const navigate = useNavigate();
  const [showItemPicker, setShowItemPicker] = useState(false);
  const [selectedItem, setSelectedItem] = useState("Please select a bank");
  const [msg, setMsg] = useState("");
  
  const [formData, setFormData] = useState({
    bank: "",
    name: "",
    account_no: "",
    phone: "",
    email: "",
    code: "",
  });

  const [toast, setToast] = useState({
    isVisible: false,
    message: "",
  });

  const showToast = (message) => {
    setToast({ isVisible: true, message });
    setTimeout(() => {
      setToast({ isVisible: false, message: "" });
    }, 3000); // Hide after 3 seconds
  };
  const handleOpenListPicker = () => {
    setShowItemPicker(!showItemPicker);
  };

  const handleReturnList = (item) => {
    setSelectedItem(item);
    setFormData((prevData) => ({
      ...prevData,
      bank: item,
    }));
    setMsg("");
  };

  const addBank = async (data) => {
    try {
      const res = await addUserBank(data);
      if (res.data.status) {
        showToast(
          `<svg xmlns="http://www.w3.org/2000/svg" width="35px" height="35px" viewBox="0 0 24 24"><path fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth={3} d="M5 14.5s1.5 0 3.5 3.5c0 0 5.559-9.167 10.5-11" color="#fff"></path></svg>${res.data.message}`
        );
      }
      navigate("/wallet/Withdraw");
    } catch (error) {
      console.log("Error", error.response);
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    if (name === "account_no" || name === "phone") {
      if (!/^\d*$/.test(value)) {
        return;
      }
    }
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const { name, account_no, phone, email, code, bank } = formData;

    // Add class to empty fields
    if (selectedItem === "Please select a bank") {
      setMsg("Please select a bank");
      return;
    } else {
      setMsg("");
    }
    if (!name) {
      $(`input[name="name"]`).addClass("error");
      return;
    } else {
      $(`input[name="name"]`).removeClass("error");
    }
    if (!account_no) {
      $(`input[name="account_no"]`).addClass("error");
      return;
    } else {
      $(`input[name="account_no"]`).removeClass("error");
    }
    if (!phone) {
      $(`input[name="phone"]`).addClass("error");
      return;
    } else {
      $(`input[name="phone"]`).removeClass("error");
    }
    if (!email) {
      $(`input[name="email"]`).addClass("error");
      return;
    } else {
      $(`input[name="email"]`).removeClass("error");
    }
    if (!code) {
      $(`input[name="code"]`).addClass("error");
      return;
    } else {
      $(`input[name="code"]`).removeClass("error");
    }
    // Validate bank account number (digits only)
    if (!validator.isNumeric(account_no)) {
      $(`input[name="account_no"]`).addClass("error");
      return;
    } else {
      $(`input[name="account_no"]`).removeClass("error");
    }

    // Validate phone number (assuming it is a valid phone number format)
    if (!validator.isMobilePhone(phone, "any", { strictMode: false })) {
      $(`input[name="phone"]`).addClass("error");
      return;
    } else {
      $(`input[name="phone"]`).removeClass("error");
    }

    // Validate email
    if (!validator.isEmail(email)) {
      $(`input[name="email"]`).addClass("error");
      return;
    } else {
      $(`input[name="email"]`).removeClass("error");
    }

    // Validate IFSC code (assuming it should be alphanumeric)
    if (!validator.isAlphanumeric(code)) {
      $(`input[name="code"]`).addClass("error");
      return;
    } else {
      $(`input[name="code"]`).removeClass("error");
    }

    addBank(formData);
  };

  useEffect(() => {
    const getBankData = async () => {
      const res = await getBanks();
      if (res.status === 200) {
        if (res.data.status === "expired") {
          navigate("/login");
        }
        const fetched_banks = res.data.data.map((obj) => obj.name);
        setBanks(fetched_banks);
      } else {
        navigate("/login");
      }
    };

    getBankData();
  }, [navigate]);
  return (
    <>
      <CustomHeader
        path={"/wallet/Withdraw"}
        bg={"var(--themeBlack)"}
        color={"var(--mainText)"}
        heading={"Add Bank account number"}
      />
      <div className="notice">
        <img src={noticeIcon} alt="" />
        <span>
          To ensure the safety of your funds, please bind your bank account
        </span>
      </div>
      <div className="bank-input-label">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="22px"
          height="22px"
          viewBox="0 0 1920 1792"
        >
          <path
            fill="var(--main-color)"
            d="m960 0l960 384v128h-128q0 26-20.5 45t-48.5 19H197q-28 0-48.5-19T128 512H0V384zM256 640h256v768h128V640h256v768h128V640h256v768h128V640h256v768h59q28 0 48.5 19t20.5 45v64H128v-64q0-26 20.5-45t48.5-19h59zm1595 960q28 0 48.5 19t20.5 45v128H0v-128q0-26 20.5-45t48.5-19z"
          />
        </svg>
        <span>Choose a bank</span>
      </div>
      <div className="select-container bank-select">
        <div className="select" onClick={handleOpenListPicker}>
          <span>{selectedItem}</span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="25px"
            height="25px"
            viewBox="0 0 24 24"
          >
            <path
              fill="none"
              stroke="#fff"
              strokeLinecap="round"
              strokeLinejoin="round"
              d="m7 10l5 5l5-5"
            />
          </svg>
        </div>
      </div>
      {msg ? <div className="err-msg text-red">{msg}</div> : ""}
      <div className="bank-input-label">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="22px"
          height="22px"
          viewBox="0 0 1280 1536"
        >
          <path
            fill="var(--main-color)"
            d="M1280 1271q0 109-62.5 187t-150.5 78H213q-88 0-150.5-78T0 1271q0-85 8.5-160.5t31.5-152t58.5-131t94-89T327 704q131 128 313 128t313-128q76 0 134.5 34.5t94 89t58.5 131t31.5 152t8.5 160.5m-256-887q0 159-112.5 271.5T640 768T368.5 655.5T256 384t112.5-271.5T640 0t271.5 112.5T1024 384"
          />
        </svg>
        <span> Full recipient's name</span>
      </div>
      <div className="bank-input-container">
        <input
          type="text"
          placeholder="Please enter the recipient's name"
          name="name"
          value={formData.name}
          onChange={handleChange}
        />
      </div>
      <div className="bank-input-label">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="25px"
          height="25px"
          viewBox="0 0 1024 1024"
        >
          <path
            fill="var(--main-color)"
            d="M928 160H96c-17.7 0-32 14.3-32 32v160h896V192c0-17.7-14.3-32-32-32M64 832c0 17.7 14.3 32 32 32h832c17.7 0 32-14.3 32-32V440H64zm579-184c0-4.4 3.6-8 8-8h165c4.4 0 8 3.6 8 8v72c0 4.4-3.6 8-8 8H651c-4.4 0-8-3.6-8-8z"
          />
        </svg>
        <span> Bank account number</span>
      </div>
      <div className="bank-input-container">
        <input
          type="text"
          name="account_no"
          placeholder="Please enter your bank account number"
          value={formData.account_no}
          onChange={handleChange}
        />
      </div>
      <div className="bank-input-label">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="25px"
          height="25px"
          viewBox="0 0 48 48"
        >
          <defs>
            <mask id="ipSPhone0">
              <g fill="none" strokeWidth="4">
                <path
                  fill="#fff"
                  stroke="#fff"
                  strokeLinejoin="round"
                  d="M8 30h32v12a2 2 0 0 1-2 2H10a2 2 0 0 1-2-2z"
                ></path>
                <path
                  stroke="#fff"
                  strokeLinejoin="round"
                  d="M40 30V6a2 2 0 0 0-2-2H10a2 2 0 0 0-2 2v24"
                ></path>
                <path stroke="#000" strokeLinecap="round" d="M22 37h4"></path>
              </g>
            </mask>
          </defs>
          <path
            className="color"
            fill="var(--main-color)"
            d="M0 0h48v48H0z"
            mask="url(#ipSPhone0)"
          ></path>
        </svg>
        <span> Phone number</span>
      </div>
      <div className="bank-input-container">
        <input
          type="text"
          name="phone"
          placeholder="Please enter your phone number"
          value={formData.phone}
          onChange={handleChange}
        />
      </div>
      <div className="bank-input-label">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="25px"
          height="25px"
          viewBox="0 0 36 36"
        >
          <path
            fill="var(--main-color)"
            d="M32.33 6a2 2 0 0 0-.41 0h-28a2 2 0 0 0-.53.08l14.45 14.39Z"
            className="clr-i-solid clr-i-solid-path-1"
          />
          <path
            fill="var(--main-color)"
            d="m33.81 7.39l-14.56 14.5a2 2 0 0 1-2.82 0L2 7.5a2 2 0 0 0-.07.5v20a2 2 0 0 0 2 2h28a2 2 0 0 0 2-2V8a2 2 0 0 0-.12-.61M5.3 28H3.91v-1.43l7.27-7.21l1.41 1.41Zm26.61 0h-1.4l-7.29-7.23l1.41-1.41l7.27 7.21Z"
            className="clr-i-solid clr-i-solid-path-2"
          />
          <path fill="none" d="M0 0h36v36H0z" />
        </svg>
        <span> Mail</span>
      </div>
      <div className="bank-input-container">
        <input
          type="text"
          name="email"
          placeholder="Please input your email"
          value={formData.email}
          onChange={handleChange}
        />
      </div>
      <div className="bank-input-label">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="25px"
          height="25px"
          viewBox="0 0 24 24"
        >
          <path
            fill="var(--main-color)"
            d="M22 18v4h-4v-3h-3v-3h-3l-2.26-2.26c-.55.17-1.13.26-1.74.26a6 6 0 0 1-6-6a6 6 0 0 1 6-6a6 6 0 0 1 6 6c0 .61-.09 1.19-.26 1.74zM7 5a2 2 0 0 0-2 2a2 2 0 0 0 2 2a2 2 0 0 0 2-2a2 2 0 0 0-2-2"
          ></path>
        </svg>
        <span> IFSC Code</span>
      </div>
      <div className="bank-input-container">
        <input
          type="text"
          name="code"
          placeholder="Please enterIFSC code"
          value={formData.code}
          onChange={handleChange}
        />
      </div>
      <div className="bank-submit-btn" onClick={handleSubmit}>
        <GradientButton
          text={"Save"}
          bg={"var(--main_gradient-color)"}
          color={"#fff"}
          padding={"13px 10px"}
        />
      </div>

      <div className="add-bank-list">
        <ListPicker
          openList={showItemPicker}
          returnItem={handleReturnList}
          list={banks}
          heading={"a Bank"}
        />
      </div>
      <Toast
        message={toast.message}
        isVisible={toast.isVisible}
        onClose={() => setToast({ isVisible: false, message: "" })}
      />
    </>
  );
};

export default AddBank;
