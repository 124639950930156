import $ from "jquery";

export const Capitalize = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const formatDate = (format, date) => {
  const d = new Date(date);
  const map = {
    d: d.getDate().toString().padStart(2, "0"),
    D: d.getDate(),
    m: (d.getMonth() + 1).toString().padStart(2, "0"),
    M: d.getMonth() + 1,
    y: d.getFullYear().toString().slice(-2),
    Y: d.getFullYear(),
    H: d.getHours().toString().padStart(2, "0"),
    h: (d.getHours() % 12 || 12).toString().padStart(2, "0"),
    M: d.getMinutes().toString().padStart(2, "0"),
    S: d.getSeconds().toString().padStart(2, "0"),
    A: d.getHours() >= 12 ? "PM" : "AM",
  };

  return format.replace(/d|D|m|M|y|Y|H|h|M|S|A/g, (matched) => map[matched]);
};

export const checkNumbers = (numStr) => {
  const nums = numStr.split(",").map(Number);

  if (nums.length !== 3) {
    return "Please provide exactly three numbers.";
  }

  const [num1, num2, num3] = nums;

  if (num1 === num2 && num2 === num3) {
    return "3 same numbers";
  }

  if (num1 !== num2 && num2 !== num3 && num1 !== num3) {
    return "3 different numbers";
  }

  if (num1 === num2 || num2 === num3 || num1 === num3) {
    return "2 same numbers";
  }

  const sortedNums = nums.slice().sort((a, b) => a - b);
  if (
    sortedNums[2] - sortedNums[1] === 1 &&
    sortedNums[1] - sortedNums[0] === 1
  ) {
    return "3 consecutive numbers";
  }

  return "Unexpected scenario.";
};

export const formatString = (str, first, last) => {
  const length = str.length;
  if (length <= 7) {
    return str;
  }

  const firstThree = str.slice(0, first);
  const lastFour = str.slice(-last);
  return `${firstThree}***${lastFour}`;
};

export const getYearsUpToNow = (startYear) => {
  const currentYear = new Date().getFullYear();
  const years = [];

  for (let year = startYear; year <= currentYear; year++) {
    years.push(year);
  }

  return years;
};

export const getMonthsUpToNow = (year) => {
  const currentYear = new Date().getFullYear();
  const currentMonth = new Date().getMonth() + 1; // 1-based month index
  const months = [];

  if (year < currentYear) {
    // If the provided year is before the current year, include all months
    for (let month = 1; month <= 12; month++) {
      months.push(month.toString().padStart(2, "0"));
    }
  } else if (year === currentYear) {
    // If the provided year is the current year, include months up to the current month
    for (let month = 1; month <= currentMonth; month++) {
      months.push(month.toString().padStart(2, "0"));
    }
  }

  return months;
};

export const getDaysInMonthUpToNow = (year, month) => {
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth() + 1; // 1-based month index
  const currentDay = currentDate.getDate();

  // Calculate the total number of days in the specified month
  const daysInMonth = new Date(year, month, 0).getDate();
  const days = [];

  if (year === currentYear && month === currentMonth) {
    // If the specified month is the current month, limit to current day
    for (let day = 1; day <= currentDay; day++) {
      days.push(day);
    }
  } else {
    // Otherwise, include all days of the specified month
    for (let day = 1; day <= daysInMonth; day++) {
      days.push(day);
    }
  }

  return days;
};

export const modifyArray = (arr, prevItems, lastItem) => {
  return [...prevItems, ...arr, lastItem];
};

export const formatAlfaNumber = (value) => {
  if (isNaN(value)) return "Invalid number";

  if (value >= 1e9) {
    return value / 1e9 + "B";
  } else if (value >= 1e6) {
    return value / 1e6 + "M";
  } else if (value >= 1e3) {
    return value / 1e3 + "K";
  } else {
    return value.toString();
  }
};

export const formatMoney = (
  amount,
  currencySymbol = "₹",
  decimalPlaces = 2
) => {
  // Convert amount to a number (parseFloat works for strings and numbers)
  const numberAmount = parseFloat(amount);

  if (isNaN(numberAmount)) return `${currencySymbol}0.00`;

  // Convert to fixed decimal places
  let formattedAmount = numberAmount.toFixed(decimalPlaces);

  // Add thousands separator
  let parts = formattedAmount.split(".");
  let integerPart = parts[0];
  let decimalPart = parts[1];

  // Use regex to add commas for thousands
  integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  // Construct the formatted amount
  return `${currencySymbol}${integerPart}.${decimalPart}`;
};

export const showLoader = (open) => {
  if (open) {
    $(".loader").removeClass("d-none");
  } else {
    $(".loader").addClass("d-none");
  }
};

export const getPercentage = (firstValue, secondValue) => {
  let percentage = (secondValue / firstValue) * 100;

  // Cap the percentage at 100%
  if (percentage > 100) {
    percentage = 100;
  }

  return percentage;
};

export const handleSpeak = (text) => {
  if (!window.speechSynthesis) {
    console.log("Sorry, your browser doesn't support Text to Speech.");
    return;
  }

  if (text.trim() !== "") {
    const utterance = new SpeechSynthesisUtterance(text);
    utterance.lang = "en-IN"; // Set language
    utterance.rate = 1; // Set rate of speech
    utterance.pitch = 1; // Set pitch

    // Define the event listeners before speaking
    utterance.onstart = () => {
      console.log("Speech started");
    };

    utterance.onend = () => {
      console.log("Speech ended");
    };

    // Speak the utterance
    speechSynthesis.speak(utterance);
  } else {
    console.log("Please enter some text to speak");
  }
};

export const getTabIndexByValue = (value) => {
  const tabs = [
    { value: 3000, reward: 60, monthly: 30 },
    { value: 30000, reward: 180, monthly: 90 },
    { value: 400000, reward: 690, monthly: 290 },
    { value: 4000000, reward: 1690, monthly: 690 },
    { value: 20000000, reward: 6900, monthly: 1690 },
    { value: 80000000, reward: 16900, monthly: 6900 },
    { value: 300000000, reward: 69000, monthly: 16900 },
    { value: 1000000000, reward: 169000, monthly: 69000 },
    { value: 5000000000, reward: 690000, monthly: 169000 },
    { value: 9999999999, reward: 1690000, monthly: 690000 },
  ];

  // Find the maximum index where the tab.value is less than or equal to the given value
  let maxIndex = 0; // Default to 0 in case no matching tab is found
  for (let i = 0; i < tabs.length; i++) {
    if (tabs[i].value <= value) {
      maxIndex = i + 1; // Update maxIndex each time the condition is met
    } else {
      break; // Break loop when we find the first value greater than input
    }
  }

  return maxIndex;
};
