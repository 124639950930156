import axios from "../api/axiosConfig";

export const getWingoList = async (page, type) => {
  const token = localStorage.getItem("token");

  const response = await axios.get("/api/user/getWingoPreiodList", {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
    params: {
      type: type,
      page: page ? page : 0,
    },
  });
  return response;
};

export const getK3List = async (page, type) => {
  const token = localStorage.getItem("token");

  const response = await axios.get("/api/user/getK3PreiodList", {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
    params: {
      type: type,
      page: page ? page : 0,
    },
  });
  return response;
};

export const getD5List = async (page, type) => {
  const token = localStorage.getItem("token");

  const response = await axios.get("/api/user/getD5PreiodList", {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
    params: {
      type: type,
      page: page ? page : 0,
    },
  });
  return response;
};

export const getTrxWinList = async (page, type) => {
  const token = localStorage.getItem("token");

  const response = await axios.get("/api/user/getTrxWinPeriodList", {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
    params: {
      type: type,
      page: page ? page : 0,
    },
  });
  return response;
};

export const getBanks = async () => {
  const token = localStorage.getItem("token");

  const response = await axios.get("/api/user/banks", {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
  return response;
};
