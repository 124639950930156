import React from "react";
import "../../assets/css/notification.css";
import CommonHeader from "../common/CommonHeader";
import { noData } from "../../assets/media";

const Notification = () => {
  const arr = [
    {
      title: "SAFE UPDATE NOTICE",
      msg: "We’re excited to announce upcoming SAFE options! Please transfer your funds from your SAFE balance to your main wallet to ensure a smooth transition. Thank you for your support and stay tuned for more details!",
    },
    {
      title: "Stay Vigilant - ClubTata Notify",
      msg: "Be cautious of counterfeit websites mimicking our ClubTata official site, do not transfer money to anyone, including agents, and keep payment receipts and UTR numbers confidential.",
    },
    {
      title: "AVOID FRAUD AND SUBMIT DEPOSIT TO SELF-SERVICE ClubTata",
      msg: "If your deposit not receive, please send it directly to ClubTata Self-service Center with provided proof deposit full detail showing (time, date, UTR number, amount and UPI ID receiver), wait till already get process, do not send to another person and trust anyone claiming to represent ClubTata. Always verify their authenticity through the official community channels provided by ClubTata. Your safety and trust in our community is very important to us.",
    },
    {
      title: "ClubTata",
      msg: "We sincerely apologize for the inconvenience caused during our recent maintenance. We diligently enhance these systems, ensuring a smoother and more efficient experience for you. Please try to submit on self-service center and email if was there an issue you encounter.",
    },
    {
      title: "Important Announcement About Deposit",
      msg: "Due to unstable of bank India will have delay or failed on payment, so if you are experiencing any issues with making a payment, we recommend switching to a different payment channel and attempting to deposit again. To ensure your transaction smooth and fast, we suggest using the barcode method or PAYTM method. Also, for using PhonePe sometimes will encounter delay or failed payment. Your satisfaction is our priority. Thank you for your understanding and continued support.",
    },
    {
      title:
        "PREVENTING BEING SCAMMED / HACKED DUE TO SOMEONE PROCLAIMING AS ClubTata CUSTOMER SERVICE",
      msg: "Our customer service never sends a link to the member. If you received a link from someone who proclaimed as ClubTata customer service, do not click the link to prevent being hacked or losing data. Thank you.",
    },
  ];
  return (
    <div className="container">
      <CommonHeader
        path="/"
        heading="Notification"
        svg={
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20px"
            height="20px"
            viewBox="0 0 24 24"
          >
            <path
              fill="var(--lightText)"
              d="m3.55 12l7.35 7.35q.375.375.363.875t-.388.875t-.875.375t-.875-.375l-7.7-7.675q-.3-.3-.45-.675T.825 12t.15-.75t.45-.675l7.7-7.7q.375-.375.888-.363t.887.388t.375.875t-.375.875z"
            />
          </svg>
        }
      />
      <div className="msg-content">
        {arr.length > 0 ? (
          arr.map((value, index) => (
            <div className="msg-container" key={index}>
              <div className="d-flex justify-between align-center">
                <div className="d-flex align-center" style={{ gap: "5px" }}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="35px"
                    height="35px"
                    viewBox="0 0 1024 1024"
                  >
                    <path
                      fill="var(--main-color)"
                      d="M880 112c-3.8 0-7.7.7-11.6 2.3L292 345.9H128c-8.8 0-16 7.4-16 16.6v299c0 9.2 7.2 16.6 16 16.6h101.6c-3.7 11.6-5.6 23.9-5.6 36.4c0 65.9 53.8 119.5 120 119.5c55.4 0 102.1-37.6 115.9-88.4l408.6 164.2c3.9 1.5 7.8 2.3 11.6 2.3c16.9 0 32-14.2 32-33.2V145.2C912 126.2 897 112 880 112M344 762.3c-26.5 0-48-21.4-48-47.8c0-11.2 3.9-21.9 11-30.4l84.9 34.1c-2 24.6-22.7 44.1-47.9 44.1"
                    />
                  </svg>
                  <span className="msg-heading">{value.title}</span>
                </div>
              </div>
              <span className="noti-msg">{value.msg}</span>
              <span className="login-time">2024-07-08 07:39:00</span>
            </div>
          ))
        ) : (
          <div className="empty">
            <img src={noData} alt="" className="noData" />
            <span>No Data</span>
          </div>
        )}
      </div>
    </div>
  );
};

export default Notification;
