import React from "react";
import homeLogo from "../../assets/img/home_logo.png";
import { lotteryLogo } from "../../assets/media";

const SplashScreen = () => {
  return (
    <>
      <div className="splash-container">
        <img src={lotteryLogo} alt="" className="img1" />
        <span>Withdrawal fast, safe & stable</span>
        <img src={homeLogo} alt="" className="img2" />
      </div>
    </>
  );
};

export default SplashScreen;
