import React, { useEffect, useState } from "react";
import CustomHeader from "../../common/CustomHeader";
import { getInviteBonusList } from "../../../utils/UserController";
import { formatDate, formatMoney } from "../../../utils/Utils";
import { noData } from "../../../assets/media";

const InvitationRecord = () => {
  const [list, setList] = useState([]);

  useEffect(() => {
    const getInviteBonusListData = async () => {
      try {
        const res = await getInviteBonusList();

        setList(res.data.data);
        console.log();
      } catch (error) {}
    };
    getInviteBonusListData();
  }, []);

  return (
    <>
      <CustomHeader
        bg={"var(--themeBlack)"}
        color={"var(--mainText)"}
        heading={"Invitation record"}
        path={"/activity/InvitationBonus"}
      />
      <div className="d-tab-content">
        <div className="deposit-history">
          {list.length > 0 ? (
            list.map((v, i) => (
              <div className="deposit-history-item" key={i}>
                <div className="history-item-header">
                  <div className="dtb">{v.uid}</div>
                  <div className="d-flex align-center left-arrow">
                    <span className={`text-box bg-success`}>Success</span>
                  </div>
                </div>
                <div className="history-item-content">
                  <div className="d-flex justify-between">
                    <span>Balance</span>
                    <span className="balance">{formatMoney(v.amount)}</span>
                  </div>
                  <div className="d-flex justify-between">
                    <span>People</span>
                    <span className="">{v.people}</span>
                  </div>
                  <div className="d-flex justify-between">
                    <span>Time</span>
                    <span>{formatDate("Y-m-d h:M A", v.created_at)}</span>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <div className="empty">
              <img src={noData} alt="" className="noData" />
              <span>No Data</span>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default InvitationRecord;
