import React from "react";
import { Link } from "react-router-dom";
import "../../../src/assets/css/header.css";
import homeLogo from "../../assets/img/home_logo.png";

const Header = () => {
  const handleInstallClick = async () => {
    const link = document.createElement("a");
    link.href = "/base.apk"; // Replace with your APK's URL
    link.download = "clubtata.apk"; // The name the file will have when downloaded
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div className="main-header">
      <div className="header-1">
        <img src={homeLogo} alt="homeLogo" className="home-logo" />
      </div>
      <div className="header-2">
        <Link to="/home/Messages">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20px"
            height="20px"
            viewBox="0 0 24 24"
          >
            <g
              fill="none"
              stroke="var(--main-color)"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1.5"
              color="var(--main-color)"
            >
              <path d="m7 8.5l2.942 1.74c1.715 1.014 2.4 1.014 4.116 0L17 8.5" />
              <path d="M2.016 13.476c.065 3.065.098 4.598 1.229 5.733c1.131 1.136 2.705 1.175 5.854 1.254c1.94.05 3.862.05 5.802 0c3.149-.079 4.723-.118 5.854-1.254c1.131-1.135 1.164-2.668 1.23-5.733c.02-.986.02-1.966 0-2.952c-.066-3.065-.099-4.598-1.23-5.733c-1.131-1.136-2.705-1.175-5.854-1.254a115 115 0 0 0-5.802 0c-3.149.079-4.723.118-5.854 1.254c-1.131 1.135-1.164 2.668-1.23 5.733a69 69 0 0 0 0 2.952" />
            </g>
          </svg>
        </Link>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="22px"
          height="22px"
          viewBox="0 0 24 24"
          onClick={handleInstallClick}
        >
          <path
            fill="var(--main-color)"
            d="M16.59 9H15V4c0-.55-.45-1-1-1h-4c-.55 0-1 .45-1 1v5H7.41c-.89 0-1.34 1.08-.71 1.71l4.59 4.59c.39.39 1.02.39 1.41 0l4.59-4.59c.63-.63.19-1.71-.7-1.71M5 19c0 .55.45 1 1 1h12c.55 0 1-.45 1-1s-.45-1-1-1H6c-.55 0-1 .45-1 1"
          />
        </svg>
      </div>
    </div>
  );
};

export default Header;
