import React, { useEffect, useState, useRef } from "react";
import Draggable from "react-draggable";
import $ from "jquery";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import BottomNav from "../common/BottomNav";
import "../../assets/css/home.css";
import lotteryLogo from "../../assets/logo/lottery.png";
import miniGameLogo from "../../assets/logo/minigamelogo.png";
import popularLogo from "../../assets/logo/populargame.png";
import d5Ball from "../../assets/img/d5ball.png";
import trxBall from "../../assets/img/trxball.png";
import user1 from "../../assets/img/user1.png";
import winning1 from "../../assets/img/winning1.png";
import leaderBg from "../../assets/img/leaderbg.png";
import leaderCrown1 from "../../assets/img/leadercrown1.png";
import leaderCrown2 from "../../assets/img/leadercrown2.png";
import leaderCrown3 from "../../assets/img/leadercrown3.png";
import no1 from "../../assets/img/no1.png";
import no2 from "../../assets/img/no2.png";
import no3 from "../../assets/img/no3.png";
import Header from "../common/Header";
import { Link, useNavigate } from "react-router-dom";
import Lottery from "../components/games/Lottery";
import MiniGame from "../components/games/MiniGame";
import Popular from "../components/games/Popular";
import floatingMsg from "../../assets/logo/floatingmsg.png";

import {
  av1,
  av12,
  av15,
  av17,
  av2,
  av3,
  av19,
  checkCircle,
  checkImg,
  av5,
  fishing,
  casino,
  sports,
  pvc,
  slots,
  main_icon,
  bannerNew1,
  bannerNew2,
  bannerNew3,
} from "../../assets/media";
import { formatMoney, handleSpeak } from "../../utils/Utils";
import Toast from "../components/Toast";
import Slots from "../components/games/Slots";
import Fishing from "../components/games/Fishing";
import PVC from "../components/games/PVC";
import Casino from "../components/games/Casino";
import Sports from "../components/games/Sports";

const images = [bannerNew1, bannerNew2, bannerNew3];

const Home = () => {
  const [activeTab, setActiveTab] = useState(0);
  const [fsChecked, setFsChecked] = useState(false);
  const navigate = useNavigate();
  const tabsRef = useRef(null);
  const [loopit, setLoopit] = useState(true);
  const [change, setChange] = useState(true);
  const [show, setShow] = useState(true);
  const [text, setText] = useState(
    "We’re excited to announce upcoming SAFE options! Please transfer your funds from your.."
  );
  const [firstDepositVisible, setFirstDepositVisible] = useState(false);
  const [notificationVisible, setNotificationVisible] = useState(true);

  const [isDragging, setIsDragging] = useState(false);

  const eventControl = (event, info) => {
    if (event.type === "mousemove" || event.type === "touchmove") {
      setIsDragging(true);
    }

    if (event.type === "mouseup" || event.type === "touchend") {
      setTimeout(() => {
        setIsDragging(false);
      }, 100);
    }
  };
  const handleCloseClick = () => {
    setFirstDepositVisible(false);
    setNotificationVisible(false);
  };

  const handleSpeakText = () => {
    handleSpeak(text);
  };

  useEffect(() => {
    const checkModals = () => {
      const noShowModal = $(document).find(".modal").hasClass("show");
      if (!noShowModal) {
        $(document).find(".overlay").addClass("d-none");
      }
    };
    checkModals();
  }, []);

  const handleInstallClick = async () => {
    const link = document.createElement("a");
    link.href = "/base.apk"; // Replace with your APK's URL
    link.download = "clubtata.apk"; // The name the file will have when downloaded
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const texts = [
    "Announcing upcoming SAFE options! Transfer funds to your main wallet.",
    "Be cautious of counterfeit websites mimicking ClubTata official site.",
    "Send deposits to ClubTata Self-service Center with proof of payment.",
    "We apologize for the inconvenience caused during our maintenance.",
    "Bank delays may affect payments; try different payment channels.",
    "Customer service never sends links; avoid clicking suspicious links.",
  ];

  const changeText = () => {
    setTimeout(() => {
      let index = Math.floor(Math.random() * texts.length);
      setText(texts[index]);
      setChange(!change);
    }, 7000);
  };

  useEffect(() => {
    changeText();
  }, [change]);

  const handleSupport = () => {
    window.location.href = "#/promotion/CustomerSupport";
  };

  const fDeposit = [
    {
      deposit: 100,
      bonus: 28,
    },
    {
      deposit: 200,
      bonus: 48,
    },
    {
      deposit: 500,
      bonus: 108,
    },
    {
      deposit: 1000,
      bonus: 188,
    },
    {
      deposit: 5000,
      bonus: 488,
    },
    {
      deposit: 24000,
      bonus: 1388,
    },
    {
      deposit: 120000,
      bonus: 5888,
    },
    {
      deposit: 240000,
      bonus: 8888,
    },
  ];

  const generateRandomUsername = () => {
    const chars = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    const numbers = "0123456789";
    const randomPart =
      Array(3)
        .fill()
        .map(() => chars.charAt(Math.floor(Math.random() * chars.length)))
        .join("") +
      "***" +
      Array(3)
        .fill()
        .map(() => numbers.charAt(Math.floor(Math.random() * numbers.length)))
        .join("");
    return randomPart;
  };

  const generateRandomAmount = () => {
    // Generate random amount between 100 and 5000
    const amount = (Math.random() * (5000 - 100) + 100).toFixed(2);
    return `₹${amount}`;
  };

  const lopper = (id) => {
    setTimeout(() => {
      let lastDiv = $("#" + id)
        .find(".winning-item")
        .last();

      lastDiv.animate(
        {
          height: "0px",
          opacity: 0,
          margin: 0,
          padding: "0px 10px 0px 10px",
        },
        200,
        function () {
          let clonedDiv = lastDiv.clone();

          // Update cloned div with random username and amount
          clonedDiv
            .find(".winning-item-user span")
            .text(generateRandomUsername());
          clonedDiv
            .find(".winning-item-game-text1")
            .text(`Receive ${generateRandomAmount()}`);

          clonedDiv.css({
            height: "",
            opacity: 0,
            margin: "",
            padding: "10px 10px 10px 10px",
          });
          lastDiv.remove();
          $("#" + id).prepend(clonedDiv);
          clonedDiv.animate(
            {
              opacity: 1,
            },
            400
          );
          setLoopit(!loopit);
        }
      );
    }, 2000);
  };

  useEffect(() => {
    lopper("winners");
  }, [loopit]);

  const [toast, setToast] = useState({
    isVisible: false,
    message: "",
  });

  const showToast = (message) => {
    setToast({ isVisible: true, message });
    setTimeout(() => {
      setToast({ isVisible: false, message: "" });
    }, 5000); // Hide after 3 seconds
  };

  const handleClick = () => {
    navigate("/wallet/Recharge");
  };

  const callGame = () => {
    showToast(
      `<svg xmlns="http://www.w3.org/2000/svg" width="35px" height="35px" viewBox="0 0 24 24"><path fill="#fff" d="M13 2v14.5h-2V2zm-2 17h2.004v2.004H11z"></path></svg>इस गेम को खेलने के लिए आपको कम से कम ₹500.0/- रुपये का रिचार्ज कराना होगा।`
    );
  };

  const tabs = [
    {
      label: "Lottery",
      logo: lotteryLogo,
      content: <Lottery viewall={true} />,
    },
    {
      label: "Mini Games",
      logo: miniGameLogo,
      content: <MiniGame viewall={true} callGame={callGame} />,
    },
    {
      label: "Popular",
      logo: popularLogo,
      content: <Popular viewall={true} callGame={callGame} />,
    },
    {
      label: "Shlots",
      logo: slots,
      content: <Slots viewall={true} callGame={callGame} />,
    },
    {
      label: "Fishing",
      logo: fishing,
      content: <Fishing viewall={true} callGame={callGame} />,
    },
    {
      label: "PVC",
      logo: pvc,
      content: <PVC viewall={true} callGame={callGame} />,
    },
    {
      label: "Casino",
      logo: casino,
      content: <Casino viewall={true} callGame={callGame} />,
    },
    {
      label: "Sports",
      logo: sports,
      content: <Sports viewall={true} callGame={callGame} />,
    },
  ];

  useEffect(() => {
    const hasSeenNotification = localStorage.getItem("hasSeenNotification");

    if (!hasSeenNotification) {
      setNotificationVisible(true);
      localStorage.setItem("hasSeenNotification", "true");
    } else {
      setNotificationVisible(false);
    }
  }, []);

  const handleHide = () => {
    setShow(false);
  };

  useEffect(() => {
    if (tabsRef.current) {
      const activeTabElement = tabsRef.current.children[activeTab];
      const tabContainerWidth = tabsRef.current.offsetWidth;
      const tabElementWidth = activeTabElement.offsetWidth;
      const scrollPosition =
        activeTabElement.offsetLeft -
        (tabContainerWidth / 2 - tabElementWidth / 2);
      tabsRef.current.scrollTo({
        left: scrollPosition,
        behavior: "smooth",
      });
    }
  }, [activeTab]);

  return (
    <>
      {/* Header */}
      <Header />
      <div className="main-home">
        <div className="slider">
          <Carousel
            autoPlay={true}
            infiniteLoop={true}
            showArrows={false}
            showStatus={false}
            slide={false}
            fade={false}
          >
            {images.map((item, index) => (
              <div key={index}>
                <img src={item} alt={index} />
              </div>
            ))}
          </Carousel>
        </div>
        <div className="newletter d-flex justify-center align-center">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20px"
            height="20px"
            viewBox="0 0 20 20"
            onClick={handleSpeakText}
          >
            <path
              fill="var(--main-color)"
              d="M2 7h4l5-4v14l-5-4H2zm12.69-2.46C14.82 4.59 18 5.92 18 10s-3.18 5.41-3.31 5.46a.49.49 0 0 1-.65-.27c-.11-.26.02-.55.27-.65c.11-.05 2.69-1.15 2.69-4.54c0-3.41-2.66-4.53-2.69-4.54a.493.493 0 0 1-.27-.65c.1-.25.39-.38.65-.27M16 10c0 2.57-2.23 3.43-2.32 3.47q-.09.03-.18.03c-.2 0-.39-.12-.47-.32c-.1-.26.04-.55.29-.65c.07-.02 1.68-.67 1.68-2.53s-1.61-2.51-1.68-2.53a.52.52 0 0 1-.29-.65c.1-.25.39-.39.65-.29c.09.04 2.32.9 2.32 3.47"
            />
          </svg>
          <div className="text">{text}</div>
          <div className="d-flex justify-center align-center btn">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="10px"
              height="10px"
              viewBox="0 0 48 48"
            >
              <path
                fill="#fff"
                stroke="#fff"
                strokeLinejoin="round"
                strokeWidth="4"
                d="M24 44c8.235 0 15-6.526 15-14.902c0-2.056-.105-4.26-1.245-7.686c-1.14-3.426-1.369-3.868-2.574-5.984c-.515 4.317-3.27 6.117-3.97 6.655c0-.56-1.666-6.747-4.193-10.45C24.537 8 21.163 5.617 19.185 4c0 3.07-.863 7.634-2.1 9.96c-1.236 2.325-1.468 2.41-3.013 4.14c-1.544 1.73-2.253 2.265-3.545 4.365C9.236 24.565 9 27.362 9 29.418C9 37.794 15.765 44 24 44Z"
              />
            </svg>
            <Link to="/main/Notification">
              <span>Detail</span>
            </Link>
          </div>
        </div>
        <div className="tabs">
          <div className="tab-buttons" ref={tabsRef}>
            {tabs.map((tab, index) => (
              <div
                key={index}
                className={`tab-button ${activeTab === index ? "active" : ""}`}
                onClick={() => setActiveTab(index)}
                // style={{ backgroundImage: `url(${tabBg2}` }}
              >
                <img
                  src={tab.logo}
                  alt=""
                  className={`${
                    activeTab === index ? "bx-burst" : ""
                  }`}
                />
                {tab.label}
              </div>
            ))}
          </div>
          <div className="tab-content">{tabs[activeTab].content}</div>
        </div>
        <div className="winning-info mt-1">
          <div className="winning-heading  mb-2">
            <span>Winning Information</span>
          </div>
          <div className="winning-items" id="winners">
            <div className="winning-item">
              <div className="winning-item-user d-flex align-center ">
                <img src={av2} alt="" />
                <span>Fuaj***OID</span>
              </div>
              <div className="winning-item-game d-flex align-center justify-center">
                <div
                  className="winning-game-image"
                  style={{ backgroundImage: `url(${d5Ball})` }}
                ></div>
                <div className="winning-item-game-text">
                  <span className="winning-item-game-text1">
                    Receive ₹1,384.00
                  </span>
                  <span className="winning-item-game-text2">
                    Winning amount
                  </span>
                </div>
              </div>
            </div>
            <div className="winning-item">
              <div className="winning-item-user d-flex align-center ">
                <img src={av5} alt="" />
                <span>Jsbs***IUS</span>
              </div>
              <div className="winning-item-game d-flex align-center justify-center">
                <div
                  className="winning-game-image"
                  style={{ backgroundImage: `url(${winning1})` }}
                ></div>
                <div className="winning-item-game-text">
                  <span className="winning-item-game-text1">
                    Receive ₹5,644.00
                  </span>
                  <span className="winning-item-game-text2">
                    Winning amount
                  </span>
                </div>
              </div>
            </div>
            <div className="winning-item">
              <div className="winning-item-user d-flex align-center ">
                <img src={av1} alt="" />
                <span>Jsjj***CIN</span>
              </div>
              <div className="winning-item-game d-flex align-center justify-center">
                <div
                  className="winning-game-image"
                  style={{ backgroundImage: `url(${winning1})` }}
                ></div>
                <div className="winning-item-game-text">
                  <span className="winning-item-game-text1">
                    Receive ₹2,114.00
                  </span>
                  <span className="winning-item-game-text2">
                    Winning amount
                  </span>
                </div>
              </div>
            </div>
            <div className="winning-item">
              <div className="winning-item-user d-flex align-center ">
                <img src={av2} alt="" />
                <span>Fuaj***OID</span>
              </div>
              <div className="winning-item-game d-flex align-center justify-center">
                <div
                  className="winning-game-image"
                  style={{ backgroundImage: `url(${trxBall})` }}
                ></div>
                <div className="winning-item-game-text">
                  <span className="winning-item-game-text1">
                    Receive ₹1,384.00
                  </span>
                  <span className="winning-item-game-text2">
                    Winning amount
                  </span>
                </div>
              </div>
            </div>
            <div className="winning-item">
              <div className="winning-item-user d-flex align-center ">
                <img src={av3} alt="" />
                <span>Neon***VYU</span>
              </div>
              <div className="winning-item-game d-flex align-center justify-center">
                <div
                  className="winning-game-image"
                  style={{ backgroundImage: `url(${winning1})` }}
                ></div>
                <div className="winning-item-game-text">
                  <span className="winning-item-game-text1">
                    Receive ₹8,800.00
                  </span>
                  <span className="winning-item-game-text2">
                    Winning amount
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="earning-chart mt-1">
          <div className="earning-heading  mb-1">
            <span>Today's earnings chart</span>
          </div>
          <div className="leader-board">
            <div className="leader-bg-image">
              <img src={leaderBg} alt="" />
            </div>
            <div className="leader-container">
              <div className="leader-container2 d-flex justify-between">
                <div className="leader">
                  <div className="leader-image-container">
                    <img src={av12} alt="" className="leader-user-image" />
                    <div className="leader-user-container">
                      <img src={leaderCrown2} alt="" className="crown" />
                      <img src={no2} alt="" className="badge" />
                    </div>
                  </div>
                  <span className="leader-userid">Jul***MDN</span>
                  <span className="leader-money">₹8,30,000.00</span>
                </div>
                <div className="leader x">
                  <div className="leader-image-container">
                    <img src={av15} alt="" className="leader-user-image" />
                    <div className="leader-user-container">
                      <img src={leaderCrown1} alt="" className="crown" />
                      <img src={no1} alt="" className="badge" />
                    </div>
                  </div>
                  <span className="leader-userid">Rob***SDK</span>
                  <span className="leader-money">₹12,50,000.00</span>
                </div>
                <div className="leader">
                  <div className="leader-image-container">
                    <img src={av17} alt="" className="leader-user-image" />
                    <div className="leader-user-container">
                      <img src={leaderCrown3} alt="" className="crown" />
                      <img src={no3} alt="" className="badge" />
                    </div>
                  </div>
                  <span className="leader-userid">Som***LAK</span>
                  <span className="leader-money">₹3,00,000.00</span>
                </div>
              </div>
            </div>
          </div>
          <div className="winning-item x">
            <div className="winning-item-user x d-flex align-center justify-center">
              <span className="pos">4</span>
              <img src={user1} alt="" />
              <span>Gop***HTO</span>
            </div>
            <div className="leader-money x">₹2,00,000.00</div>
          </div>
          <div className="winning-item x">
            <div className="winning-item-user x d-flex align-center justify-center">
              <span className="pos">5</span>
              <img src={av19} alt="" />
              <span>Nar***AMA</span>
            </div>
            <div className="leader-money x">₹85,000.00</div>
          </div>
        </div>
        <div style={{ height: "240px" }}></div>
      </div>
      {/* Bottom Navigation */}
      <BottomNav />
      <div
        className={`model ${notificationVisible ? "show" : ""}`}
        id="notification"
      >
        <div
          className="model-container"
          style={{ width: "75%", height: "60%" }}
        >
          <div className="model-policy-header">
            <span>Notification</span>
          </div>
          <div className="model-policy-content">
            <p>
              <b>🎰 Welcome back CLUBTATA member 🎰</b>
              <br />
              <br />
              <b>
                Reminder: If your deposit is not received, please send it
                directly to
                <u>
                  <a href="#" target="_blank" rel="noopener noreferrer">
                    CLUBTATA Self-service Center
                  </a>
                </u>
                , wait until it's processed, and do not send it to another
                person who claims to be on behalf of CLUBTATA.
                <br />
              </b>
              <br />
            </p>
            <p>
              <b>🔥🔥 We have many bonuses for you to claim 🔥🔥</b>
            </p>
            <p>
              <b>1. First Deposit Bonus 🎉🎉</b>
            </p>
            <p>
              <b>
                2. Attendance Bonus{" "}
                <span
                  style={{
                    fontFamily: "Helvetica Neue, Helvetica, Arial, sans-serif",
                  }}
                >
                  💯💯
                </span>
              </b>
            </p>
            <p>
              <b>3. Gifts Code 🎁🎁</b>
            </p>
            <p>
              <b
                style={{
                  fontFamily: "Helvetica Neue, Helvetica, Arial, sans-serif",
                }}
              >
                4. Invitation Bonus 🎉🎉
              </b>
              <br />
            </p>
            <p>
              <b>5. Betting Rebate 💎💎</b>
              <br />
              <b
                style={{
                  fontFamily: "Helvetica Neue, Helvetica, Arial, sans-serif",
                }}
              >
                6. Super Jackpot 💯💯
              </b>
            </p>
            <p>
              <b>
                7. VIP Bonus{" "}
                <span
                  style={{
                    fontFamily: "Helvetica Neue, Helvetica, Arial, sans-serif",
                  }}
                >
                  💎💎
                </span>
              </b>
            </p>
            <p
              style={{
                fontFamily: "Helvetica Neue, Helvetica, Arial, sans-serif",
              }}
            >
              <br />
              <b>
                Join us and become part of our agent program to enjoy amazing
                benefits. The best aspect of joining our agent program is that
                the more you invite and the higher level of your downlines, the
                greater the bonuses you may receive, leading to more downlines.
              </b>
            </p>
            <p
              style={{
                fontFamily: "Helvetica Neue, Helvetica, Arial, sans-serif",
              }}
            >
              <b>
                <br />
              </b>
            </p>
            <p>
              <b>
                Having trouble with deposits not being received, bank data
                issues, forgotten phone numbers, deleted USDT, or password
                changes?
              </b>
            </p>
            <p>
              <b>
                Rest assured, you can submit the issue on the
                <a href="#" target="_blank" rel="noopener noreferrer">
                  CLUBTATA Self-service Center
                </a>{" "}
                and fill in the required information.
              </b>
            </p>
            <p>
              <b>
                Don’t know how to submit a problem on our self-service
                center?&nbsp;
              </b>
            </p>
            <p>
              <b>
                Don’t worry, you can see the step-by-step guide by clicking this
                <a href="#" target="_blank" rel="noopener noreferrer">
                  LINK
                </a>
                .
                <br />
                <br />
              </b>
            </p>
            <p>
              <b>
                If you have a problem with withdrawals not being received for
                more than 3 days / 72 hours, you can submit the problem to
                CLUBTATA via email by checking this
                <a href="#" target="_blank" rel="noopener noreferrer">
                  LINK
                </a>
                .
                <br />
                <br />
                <br />
              </b>
            </p>
          </div>
          <div className="model-policy-footer">
            <div
              className="i-know-btn"
              onClick={() => {
                setNotificationVisible(false);
                setTimeout(() => {
                  setFirstDepositVisible(true);
                }, 1000);
              }}
            >
              Confirm
            </div>
          </div>
        </div>
      </div>
      <div
        className={`model ${firstDepositVisible ? "show" : ""}`}
        id="firstDeposit"
      >
        <div
          className="model-container"
          style={{ width: "80%", height: "70%", position: "relative" }}
        >
          <div
            className="model-policy-header"
            style={{ background: "var(--main-color)", height: "80px" }}
          >
            <div
              className="d-flex flex-col align-center"
              style={{ gap: "5px" }}
            >
              <span>Extra first deposit bonus</span>
              <span style={{ fontSize: ".7rem" }}>
                Each account can only receive rewards once
              </span>
            </div>
          </div>
          <div className="model-policy-content" style={{ padding: "10px" }}>
            {fDeposit.map((v, i) => (
              <div className="first_list-item" key={i}>
                <div className="fs-head">
                  <div className="fs-title">
                    <span>First deposit </span>
                    <span style={{ color: "#ff9832" }}>{v.deposit}</span>
                  </div>
                  <span style={{ color: "#ff9832" }}>
                    + {formatMoney(v.bonus)}
                  </span>
                </div>
                <div className="fs-des">
                  Deposit {v.deposit} for the first time and you will receive{" "}
                  {v.bonus} bonus
                </div>
                <div className="d-flex align-center justify-between">
                  <div className="fs-comp">
                    <span>0</span>/<span>{v.deposit}</span>
                  </div>
                  <div className={`fs-deposit-btn`} onClick={handleClick}>
                    Deposit
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div
            className="model-policy-footer"
            style={{ justifyContent: "space-around" }}
          >
            <img
              src={fsChecked ? checkImg : checkCircle}
              alt=""
              style={{ width: "22px", filter: "hue-rotate(208deg)" }}
              onClick={() => {
                setFsChecked(!fsChecked);
              }}
            />
            <span style={{ color: "#768096", fontSize: ".8rem" }}>
              No more reminders today
            </span>
            <Link to={"/activity/FirstDeposit"}>
              <div
                className="i-know-btn"
                style={{
                  width: "max-content",
                  padding: "6px 20px",
                  color: "#fff",
                }}
                onClick={handleCloseClick}
              >
                Activity
              </div>
            </Link>
          </div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="50px"
            height="50px"
            viewBox="0 0 24 24"
            style={{ position: "absolute", bottom: "-50px" }}
            onClick={handleCloseClick}
          >
            <path
              fill="#fff"
              d="m8.4 16.308l3.6-3.6l3.6 3.6l.708-.708l-3.6-3.6l3.6-3.6l-.708-.708l-3.6 3.6l-3.6-3.6l-.708.708l3.6 3.6l-3.6 3.6zM12.003 21q-1.866 0-3.51-.708q-1.643-.709-2.859-1.924t-1.925-2.856T3 12.003t.709-3.51Q4.417 6.85 5.63 5.634t2.857-1.925T11.997 3t3.51.709q1.643.708 2.859 1.922t1.925 2.857t.709 3.509t-.708 3.51t-1.924 2.859t-2.856 1.925t-3.509.709M12 20q3.35 0 5.675-2.325T20 12t-2.325-5.675T12 4T6.325 6.325T4 12t2.325 5.675T12 20m0-8"
            ></path>
          </svg>
        </div>
      </div>

      <>
        {show ? (
          <div className="download-btn">
            <img src={"/brand-logo.png"} alt="" onClick={handleInstallClick} />
            <span onClick={handleInstallClick}>Add to Desktop</span>
            <svg
              className="line"
              width="1"
              height="30"
              viewBox="0 0 1 60"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <line x1="0.5" x2="0.5" y2="60" stroke="white"></line>
            </svg>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="30px"
              height="30px"
              viewBox="0 0 21 21"
              onClick={handleHide}
            >
              <g
                fill="none"
                fillRule="evenodd"
                stroke="#fff"
                strokeLinecap="round"
                strokeLinejoin="round"
                transform="translate(2 2)"
              >
                <circle cx={8.5} cy={8.5} r={8}></circle>
                <path d="m5.5 5.5l6 6m0-6l-6 6"></path>
              </g>
            </svg>
          </div>
        ) : (
          ""
        )}
      </>
      <Draggable bounds="parent" onDrag={eventControl} onStop={eventControl}>
        <div
          className="floating-msg"
          onClick={() => !isDragging && handleSupport()}
          onTouchEnd={() => !isDragging && handleSupport()}
        >
          <img src={floatingMsg} alt="" />
        </div>
      </Draggable>
      <Toast
        message={toast.message}
        isVisible={toast.isVisible}
        onClose={() => setToast({ isVisible: false, message: "" })}
      />
    </>
  );
};

export default Home;
