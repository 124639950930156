import React, { useEffect, useState } from "react";
import BottomNav from "../common/BottomNav";
import CustomHeader from "../common/CustomHeader";
import "../../assets/css/wallet.css";
import {
  rechargeHistory,
  rechargeIcon,
  walletBg,
  widthdrawBlue,
  withdrawHistory,
} from "../../assets/media";
import GradientButton from "../components/GradientButton";
import { Link, useNavigate } from "react-router-dom";
import { getUser } from "../../utils/UserController";
import { formatMoney } from "../../utils/Utils";

const Wallet = () => {
  const navigate = useNavigate();
  const [user, setUser] = useState({});
  const [btnTxt, setBtnTxt] = useState("Main wallet transfer");
  const [recalling, setRecalling] = useState("");
  const [recalltime, setRecalltime] = useState(5);
  const [thirdPercent, setThirdPercent] = useState(0);
  const [mainPercent, setMainPercent] = useState(0);
  const [recallingbg, setRecallingbg] = useState(
    "linear-gradient(90deg, var(--main-color) 0%, var(--main-color2) 100%)"
  );

  const gamelist = [
    { amt: 0.0, title: "Lottery" },
    { amt: 0.0, title: "TB_Chess" },
    { amt: 0.0, title: "Wickets9" },
    { amt: 0.0, title: "JILI" },
    { amt: 0.0, title: "MG" },
    { amt: 0.0, title: "JDB" },
    { amt: 0.0, title: "EVO_Video" },
    { amt: 0.0, title: "TB" },
    { amt: 0.0, title: "Card365" },
    { amt: 0.0, title: "AG_Video" },
    { amt: 0.0, title: "PG" },
  ];

  const walletTransfer = () => {
    setThirdPercent(100);
    setMainPercent(100);
    setBtnTxt("Recalling");
    setRecallingbg("linear-gradient(90deg, #CCCEDC 15.38%, #CDCFDD 98.73%)");

    setRecalling(recalltime); // Set the initial recalling time

    const recaller = setInterval(() => {
      setRecalling((prevTime) => {
        if (prevTime <= 1) {
          clearInterval(recaller);
          setRecalling("");
          setBtnTxt("Main wallet transfer"); // Reset the button text after the countdown ends
          setRecallingbg(
            "linear-gradient(90deg, var(--main-color) 0%, var(--main-color2) 100%)"
          ); // Reset the background
          setThirdPercent(0);
          setMainPercent(0);
          return 0; // Return 0 as the final state
        }
        return prevTime - 1; // Decrease the recall time by 1
      });
    }, 1000);
  };

  useEffect(() => {
    const getUserData = async () => {
      const userData = await getUser();
      if (userData.status === 200) {
        if (userData.data.status === "expired") {
          navigate("/login");
        }
        setUser(userData.data.data);
      } else {
        navigate("/login");
      }
    };
    getUserData();
  }, []);

  return (
    <>
      <CustomHeader
        path={"/promotion"}
        bg={"var(--main_gradient-color)"}
        color={"#fff"}
        heading={"Wallet"}
      />
      <div className="wallet-banner">
        <img src={walletBg} alt="" />
        <div className="balance">{formatMoney(user?.money)}</div>
        <div className="tb">Total balance</div>
        <div className="total-container">
          <div className="d-flex flex-col align-center" style={{ gap: "5px" }}>
            <span className="total-amt">
              {formatMoney(user.withdraw_money)}
            </span>
            <span className="total-txt">Total amount</span>
          </div>
          <div className="d-flex flex-col align-center" style={{ gap: "5px" }}>
            <span className="total-amt">
              {formatMoney(user.deposite_money)}
            </span>
            <span className="total-txt">Total deposit amount</span>
          </div>
        </div>
      </div>
      <div className="transfer">
        <div className="circle-container">
          <div className="d-flex flex-col align-center">
            <div className="wallet-circle">
              <div
                className="fill"
                style={{
                  background: `conic-gradient(var(--main-color) 100%, #f6f6f6 0% 100%)`,
                }}
              ></div>
              <div className="space"></div>
              <span className="percent">100%</span>
            </div>
            <div className="d-flex flex-col align-center mw">
              <span className="ff-inter">{formatMoney(user?.money)}</span>
              <span className="ff-inter">Main wallet</span>
            </div>
          </div>
          <div className="d-flex flex-col align-center">
            <div className="wallet-circle">
              <div
                className="fill"
                style={{
                  background: `conic-gradient(var(--main-color) 0% ${thirdPercent}%, #f6f6f6 0% 100%)`,
                }}
              ></div>
              <div className="space"></div>
              <span className="percent">{thirdPercent}%</span>
            </div>
            <div className="d-flex flex-col align-center mw">
              <span className="ff-inter">₹0.00</span>
              <span className="ff-inter">3rd party wallet</span>
            </div>
          </div>
        </div>
        <div className="btn" onClick={walletTransfer}>
          <GradientButton
            text={btnTxt + " " + recalling}
            width={"95%"}
            path={""}
            textsize="1rem"
            fw={"bold"}
            bg={recallingbg}
            color={"#fff"}
          />
        </div>
        <div className="money-management">
          <Link to={"/wallet/Recharge"} className="img-btn">
            <img src={rechargeIcon} alt="" />
            <span className="btn-txt">Deposit</span>
          </Link>
          <Link to={"/wallet/Withdraw"} className="img-btn">
            <img src={widthdrawBlue} alt="" />
            <span className="btn-txt">Withdraw</span>
          </Link>
          <Link to={"/wallet/RechargeHistory"} className="img-btn">
            <img src={rechargeHistory} alt="" />
            <span className="btn-txt">Deposit history</span>
          </Link>
          <Link to={"/wallet/WithdrawHistory"} className="img-btn">
            <img src={withdrawHistory} alt="" />
            <span className="btn-txt">Withdrawal history</span>
          </Link>
        </div>
      </div>
      <div className="gamelist">
        {gamelist.map((item, i) => (
          <div className={`gamelist-item ${i === 0 ? "active" : ""}`} key={i}>
            <span className={`amt ${i === 0 ? "text-white" : ""}`}>
              {i === 0 ? user?.money : 0}
            </span>
            <span className="title">{item.title}</span>
          </div>
        ))}
      </div>
      <div className="size-box-50"></div>
      <div className="size-box-50"></div>
      <BottomNav />
    </>
  );
};

export default Wallet;
