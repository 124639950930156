import React, { useEffect, useRef, useState } from "react";
import CustomHeader from "../../common/CustomHeader";
import { useNavigate } from "react-router-dom";
import { allTab, upiIcon, paytmIcon, leftArrow, noData } from "../../../assets/media";
import { formatDate, formatMoney } from "../../../utils/Utils";
import DatePicker from "../DatePicker";
import ListPicker from "../ListPicker";
import { getDepositList } from "../../../utils/UserController";
import Toast from "../Toast";

const RechargeHistory = () => {
  const now = new Date();
  const currentdate = `${now.getFullYear()}-${
    now.getMonth() + 1
  }-${now.getDate()}`;
  const [selectedDate, setSelectedDate] = useState("Choose a date");
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [showItemPicker, setShowItemPicker] = useState(false);
  const [selectedItem, setSelectedItem] = useState("All");
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState(0);
  const tabsRef = useRef(null);
  const [depositHistory, setDepositHistory] = useState([]);

  const tabs = [
    {
      image: allTab,
      label: "All",
    },
    {
      image: upiIcon,
      label: "UPI",
    },
    {
      image: paytmIcon,
      label: "UPI-transfer",
    },
    {
      image: upiIcon,
      label: "UPI-PAY",
    },
    {
      image: paytmIcon,
      label: "Paytm",
    },
  ];

  const handleOpenDatePicker = () => {
    setShowDatePicker(!showDatePicker);
  };

  const handleReturnDate = (date) => {
    setSelectedDate((val) => {
      return date;
    });
  };

  const [toast, setToast] = useState({
    isVisible: false,
    message: "",
  });

  const showToast = (message) => {
    setToast({ isVisible: true, message });
    setTimeout(() => {
      setToast({ isVisible: false, message: "" });
    }, 3000); // Hide after 3 seconds
  };

  const handleCopy = (txt) => {
    navigator.clipboard
      .writeText(txt)
      .then(() => {
        showToast(
          `<svg width="25px" height="25px" viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" class="iconify iconify--emojione-monotone" preserveAspectRatio="xMidYMid meet" fill="#fff" stroke="#fff"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"><path d="M56 2L18.8 42.909L8 34.729H2L18.8 62L62 2z" fill="#fff"></path></g></svg>Copy successful`
        );
      })
      .catch((err) => {
        showToast("Failed to copy");
      });
  };

  const handleOpenListPicker = () => {
    setShowItemPicker(!showItemPicker);
  };

  const handleReturnList = (item) => {
    setSelectedItem((val) => {
      return item;
    });
  };

  const filterList = ["all", "pending", "success", "failed"];

  const goBack = () => {
    navigate(-1);
  };

  useEffect(() => {
    if (tabsRef.current) {
      const activeTabElement = tabsRef.current.children[activeTab];
      const tabContainerWidth = tabsRef.current.offsetWidth;
      const tabElementWidth = activeTabElement.offsetWidth;
      const scrollPosition =
        activeTabElement.offsetLeft -
        (tabContainerWidth / 2 - tabElementWidth / 2);
      tabsRef.current.scrollTo({
        left: scrollPosition,
        behavior: "smooth",
      });
    }
  }, [activeTab]);

  useEffect(() => {
    const getDepositListData = async () => {
      try {
        const res = await getDepositList(
          tabs[activeTab].label,
          selectedItem,
          selectedDate
        );
        setDepositHistory(res.data.data);
      } catch (error) {
        setDepositHistory([]);
        console.log(error);
      }
    };

    getDepositListData();
  }, [selectedItem, selectedDate, activeTab]);

  return (
    <>
      <CustomHeader
        click={goBack}
        bg={"var(--themeBlack)"}
        color={"var(--mainText)"}
        heading={"Deposit history"}
      />
      <div className="games-tabs-container" ref={tabsRef}>
        {tabs.map((tab, index) => (
          <div
            key={index}
            className={`d-tab ${index === activeTab ? "active" : ""}`}
            onClick={() => setActiveTab(index)}
          >
            <img
              src={tab.image}
              alt=""
              style={{
                filter: `${activeTab === 0 ? "brightness(5)" : ""}`,
              }}
            />
            {tab.label}
          </div>
        ))}
      </div>
      <div className="select-container">
        <div className="select" onClick={handleOpenListPicker}>
          <span style={{ textTransform: "capitalize" }}>{selectedItem}</span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="25px"
            height="25px"
            viewBox="0 0 24 24"
          >
            <path
              fill="none"
              stroke="#000"
              strokeLinecap="round"
              strokeLinejoin="round"
              d="m7 10l5 5l5-5"
            />
          </svg>
        </div>
        <div className="select" onClick={handleOpenDatePicker}>
          <span>{selectedDate}</span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="25px"
            height="25px"
            viewBox="0 0 24 24"
          >
            <path
              fill="none"
              stroke="#000"
              strokeLinecap="round"
              strokeLinejoin="round"
              d="m7 10l5 5l5-5"
            />
          </svg>
        </div>
      </div>

      <div className="d-tab-content">
        {depositHistory.length > 0 ? (
          <>
            <div className="deposit-history">
              {depositHistory.map((v, i) => (
                <div className="deposit-history-item" key={i}>
                  <div className="history-item-header">
                    <div className="dtb">Deposit</div>
                    <div className="d-flex align-center left-arrow">
                      <span
                        style={{
                          color:
                            v.status === "approved"
                              ? "#00ae50"
                              : v.status === "rejected"
                              ? "var(--main-color)"
                              : "#ff9832",
                        }}
                      >
                        {v.status === "approved"
                          ? "Complete"
                          : v.status === "rejected"
                          ? "Rejected"
                          : "To be paid"}
                      </span>
                      <img src={leftArrow} alt={""} />
                    </div>
                  </div>
                  <div className="history-item-content">
                    <div className="d-flex justify-between align-center">
                      <span>Balance</span>
                      <span className="balance">
                        {formatMoney(v.deposit_amount)}
                      </span>
                    </div>
                    <div className="d-flex justify-between align-center">
                      <span>Type</span>
                      <span>{v.pay_type}</span>
                    </div>
                    <div className="d-flex justify-between align-center">
                      <span>Time</span>
                      <span>{formatDate("Y-m-d h:M A", v.created_at)}</span>
                    </div>
                    <div className="d-flex justify-between align-center">
                      <span>Order number</span>
                      <div className="d-flex">
                        <span>{v.order_id}</span>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="15px"
                          height="15px"
                          viewBox="0 0 256 256"
                          onClick={() => handleCopy(v?.order_id)}
                        >
                          <path
                            fill="#768096"
                            d="M216 32H88a8 8 0 0 0-8 8v40H40a8 8 0 0 0-8 8v128a8 8 0 0 0 8 8h128a8 8 0 0 0 8-8v-40h40a8 8 0 0 0 8-8V40a8 8 0 0 0-8-8m-56 176H48V96h112Zm48-48h-32V88a8 8 0 0 0-8-8H96V48h112Z"
                          ></path>
                        </svg>
                      </div>
                    </div>
                    <div className="d-flex justify-between align-center">
                      <span>Remark</span>
                      <span>{v.remarks}</span>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </>
        ) : (
          <div className="empty">
            <img src={noData} alt="" className="noData" />
            <span>No Data</span>
          </div>
        )}
      </div>
      <DatePicker openDate={showDatePicker} returnDate={handleReturnDate} />
      <ListPicker
        openList={showItemPicker}
        returnItem={handleReturnList}
        list={filterList}
        heading={"an option"}
      />
      <Toast
        message={toast.message}
        isVisible={toast.isVisible}
        onClose={() => setToast({ isVisible: false, message: "" })}
      />
    </>
  );
};

export default RechargeHistory;
