import React, { useEffect, useState } from "react";
import CommonHeader from "../common/CommonHeader";
import GradientButton from "./GradientButton";
import { getIssues, getUser, submitIssue } from "../../utils/UserController";
import { formatDate, showLoader } from "../../utils/Utils";
import Toast from "./Toast";
import { noData } from "../../assets/media";

const ReportIssue = () => {
  const [des, setDes] = useState("");
  const [file, setFile] = useState(null);
  const [user, setUser] = useState({});
  const [issues, setIssues] = useState({});
  const [toast, setToast] = useState({
    isVisible: false,
    message: "",
  });
  const showToast = (message) => {
    setToast({ isVisible: true, message });
    setTimeout(() => {
      setToast({ isVisible: false, message: "" });
    }, 3000); // Hide after 3 seconds
  };

  const handleSubmit = async () => {
    if (!des) {
      showToast(
        `<svg xmlns="http://www.w3.org/2000/svg" width="35px" height="35px" viewBox="0 0 24 24"><path fill="#fff" d="M13 2v14.5h-2V2zm-2 17h2.004v2.004H11z"></path></svg>Write an issue description`
      );
      return false;
    }
    if (!file) {
      showToast(
        `<svg xmlns="http://www.w3.org/2000/svg" width="35px" height="35px" viewBox="0 0 24 24"><path fill="#fff" d="M13 2v14.5h-2V2zm-2 17h2.004v2.004H11z"></path></svg>Select a file`
      );
      return false;
    }
    const formData = new FormData();
    formData.append("description", des);
    formData.append("screenshot", file);
    formData.append("uid", user?.uid);
    formData.append("phone", user?.phone);

    try {
      showLoader(true);

      const res = await submitIssue(formData);
      showLoader(false);
      showToast(
        `<svg xmlns="http://www.w3.org/2000/svg" width="35px" height="35px" viewBox="0 0 24 24"><path fill="#fff" d="M13 2v14.5h-2V2zm-2 17h2.004v2.004H11z"></path></svg>${res.data.message}`
      );

      setDes("");
      setFile("");
    } catch (error) {}
  };
  useEffect(() => {
    const getUserData = async () => {
      showLoader(true);
      try {
        const res = await getUser();
        setUser(res.data.data);
        showLoader(false);
      } catch (error) {
        console.log(error);
      }
    };
    const getIssuesData = async () => {
      showLoader(true);
      try {
        const res = await getIssues();
        setIssues(res.data.data);
        showLoader(false);
      } catch (error) {
        console.log(error);
      }
    };
    getIssuesData();
    getUserData();
  }, [toast]);
  return (
    <>
      <CommonHeader
        path="/"
        heading="Submit issue"
        svg={
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20px"
            height="20px"
            viewBox="0 0 24 24"
          >
            <path
              fill="var(--lightText)"
              d="m3.55 12l7.35 7.35q.375.375.363.875t-.388.875t-.875.375t-.875-.375l-7.7-7.675q-.3-.3-.45-.675T.825 12t.15-.75t.45-.675l7.7-7.7q.375-.375.888-.363t.887.388t.375.875t-.375.875z"
            />
          </svg>
        }
      />
      <div className="issue-container">
        <div className="earning-heading mt-2">
          <span>Write your here</span>
        </div>
        <div className="input-container">
          <div className="input-group">
            <textarea
              className="d-input password"
              value={des}
              onChange={(e) => setDes(e.target.value)}
              placeholder="Write issue description"
              style={{
                resize: "vertical",
                height: "85px",
                fontFamily: "inter",
              }}
              rows={7}
            ></textarea>
          </div>
        </div>
        <div className="earning-heading  mb-1">
          <span>Upload screenshot here</span>
        </div>
        <input
          type="file"
          id="file"
          onChange={(e) => setFile(e.target.files[0])}
          style={{ width: "83px" }}
        />
        <div
          className="make-att-btn"
          style={{ margin: "50px auto" }}
          onClick={handleSubmit}
        >
          <GradientButton
            bg={"var(--main_gradient-color)"}
            text={"Submit"}
            color={"#fff"}
            fw={"bold"}
          />
        </div>
      </div>
      <div className="list-container">
        <div className="earning-heading  mb-1">
          <span>Submitted issues</span>
        </div>
        {(Array.isArray(issues) ? issues : []).length > 0 ? (
          (Array.isArray(issues) ? issues : []).map((v, i) => (
            <div className="issue" key={i}>
              <h5>{v.des}</h5>
              {v.reply && (
                <p>
                  <span style={{ color: "#00ae50" }}>Reply by Admin : </span>
                  {v.reply}
                </p>
              )}
              <div className="footer">
                <span className="login-time" style={{ margin: "0" }}>
                  {formatDate("Y-m-d h:M A", v.created_at)}
                </span>
                <div className={`status ${v.view == "seen" ? "active" : ""}`}>
                  {v.view}
                </div>
              </div>
            </div>
          ))
        ) : (
          <div className="empty">
            <img src={noData} alt="" className="noData" />
            <span>No Data</span>
          </div>
        )}
      </div>
      <div className="size-box-100"></div>
      <Toast
        message={toast.message}
        isVisible={toast.isVisible}
        onClose={() => setToast({ isVisible: false, message: "" })}
      />
    </>
  );
};

export default ReportIssue;
