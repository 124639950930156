import React, { useEffect, useState } from "react";
import { HashRouter } from "react-router-dom";
import $ from "jquery";
import MyRoutes from "./user/common/MyRoutes";
import "./assets/css/icon.css";
import DownMode from "./DownMode";
import SplashScreen from "./user/components/SplashScreen";
import loadingRing from "./assets/img/spin_ring.png";



const App = () => {
  const down = false;
  const [showSplashScreen, setShowSplashScreen] = useState(true);
  const timer = setTimeout(() => {
    setShowSplashScreen(false);
    clearTimeout(timer);
  }, 1000);

  useEffect(() => {
    const adjustFontSize = () => {
      if (window.innerWidth < 400) {
        $("html").css("font-size", "13.5px"); // Smaller font size for small screens
      } else {
        $("html").css("font-size", "16px"); // Default font size
      }
    };
    adjustFontSize();
    $(window).on("resize", adjustFontSize);
    return () => {
      $(window).off("resize", adjustFontSize);
    };
  }, []);
  if (down) {
    return <DownMode />;
  }
  if (showSplashScreen) {
    return <SplashScreen />;
  }
  return (
    <HashRouter>
      <div className="container">
        <MyRoutes />
        <div className="loader d-none">
          <div className="loading-box">
            <img src={"/brand-logo.png"} alt="" className="loading-mid" />
            <img src={loadingRing} alt="" className="loading-ring amin-spin" />
          </div>
        </div>
        <div className="overlay d-none"></div>
        <div className="small-msg-popup" id="smallPopup"></div>
        <div className="model" id="agreeModel">
          <div className="model-container">
            <div className="model-policy-header">
              <span>《Pre-sale rules》</span>
            </div>
            <div className="model-policy-content">
              "In order to protect the legitimate rights and interests of users
              participating in the pre-sale and maintain the normal operating
              order of the pre-sale, these rules are formulated in accordance
              with relevant agreements and laws and regulations. country Chapter
              1 Definition1.1 Pre-sale definition: refers to a sales model in
              which a seller offers a bundle of a product or service, collects
              consumer orders through product tools before selling, and makes it
              available to customers. consumers of goods and/or services by
              prior agreement1.2 Presale mode is "deposit" mode. "Consignment"
              refers to the pre-delivery of a fixed number of items prior to
              sale. "Deposit" Scam Join mini games for a chance to win more
              deposits. Deposits can be exchanged directly for goods. Deposit is
              not refundable.1.3 Pre-sale product: A product that is shipped by
              the seller using the pre-sale product tool. Only highlight the
              word presale on the product name or product detail page, and
              products that do not use the presale product tool are not presale.
              1.4 Pre-sale system: refers to the system product tool that helps
              sellers to sell samples before selling.1.5 Product price before
              selling: is the selling price of the product before selling. The
              price of pre-sale items consists of two parts: deposit and final
              payment. "
            </div>
            <div className="model-policy-footer">
              <div
                className="i-know-btn"
                onClick={() => {
                  $("#agreeModel").addClass("hide");
                }}
              >
                I know
              </div>
            </div>
          </div>
        </div>
        <div className="model" id="howtoPlayModel">
          <div className="model-container">
            <div className="model-policy-header">
              <span>How to play</span>
            </div>
            <div className="model-policy-content">
              <p
                style={{
                  fontFamily:
                    "Arial, Microsoft YaHei, \\5FAE软雅黑, \\5B8B体, Malgun Gothic, Meiryo, sans-serif",
                }}
              >
                1 minute 1 issue, 45 seconds to order, 15 seconds waiting for
                the draw. It opens all day. The total number of trades is 1440
                issues.
              </p>
              <p
                style={{
                  fontFamily:
                    "Arial, Microsoft YaHei, \\5FAE软雅黑, \\5B8B体, Malgun Gothic, Meiryo, sans-serif",
                }}
              >
                If you spend 100 to trade, after deducting 2 service fee, your
                contract amount is 98:
              </p>
              <p
                style={{
                  fontFamily:
                    "Arial, Microsoft YaHei, \\5FAE软雅黑, \\5B8B体, Malgun Gothic, Meiryo, sans-serif",
                }}
              >
                1. Select green: if the result shows 1, 3, 7, 9 you will get
                (98*2) 196; if the result shows 5, you will get (98*1.5) 147
              </p>
              <p
                style={{
                  fontFamily:
                    "Arial, Microsoft YaHei, \\5FAE软雅黑, \\5B8B体, Malgun Gothic, Meiryo, sans-serif",
                }}
              >
                2. Select red: if the result shows 2, 4, 6, 8 you will get
                (98*2) 196; if the result shows 0, you will get (98*1.5) 147
              </p>
              <p
                style={{
                  fontFamily:
                    "Arial, Microsoft YaHei, \\5FAE软雅黑, \\5B8B体, Malgun Gothic, Meiryo, sans-serif",
                }}
              >
                3. Select violet: if the result shows 0 or 5, you will get
                (98*4.5) 441
              </p>
              <p
                style={{
                  fontFamily:
                    "Arial, Microsoft YaHei, \\5FAE软雅黑, \\5B8B体, Malgun Gothic, Meiryo, sans-serif",
                }}
              >
                4. Select number: if the result is the same as the number you
                selected, you will get (98*9) 882
              </p>
              <p
                style={{
                  fontFamily:
                    "Arial, Microsoft YaHei, \\5FAE软雅黑, \\5B8B体, Malgun Gothic, Meiryo, sans-serif",
                }}
              >
                5. Select big: if the result shows 5, 6, 7, 8, 9 you will get
                (98*2) 196
              </p>
              <p
                style={{
                  fontFamily:
                    "Arial, Microsoft YaHei, \\5FAE软雅黑, \\5B8B体, Malgun Gothic, Meiryo, sans-serif",
                }}
              >
                6. Select small: if the result shows 0, 1, 2, 3, 4 you will get
                (98*2) 196
              </p>
            </div>
            <div className="model-policy-footer">
              <div
                className="i-know-btn"
                onClick={() => {
                  $("#howtoPlayModel").addClass("hide");
                }}
              >
                Close
              </div>
            </div>
          </div>
        </div>
        <div className="model" id="howtoPlayModelK3">
          <div className="model-container">
            <div className="model-policy-header">
              <span>How to play</span>
            </div>
            <div className="model-policy-content">
              <p>
                <font>
                  Fast 3 open with 3 numbers in each period as the opening
                  number，The opening numbers are 111 to 666 Natural number，No
                  zeros in the array，And the opening numbers are in no
                  particular order，Quick 3 is to guess all or part of the 3
                  winning numbers.
                </font>
              </p>
              <p>Sum Value</p>
              <p>Place a bet on the sum of three numbers</p>
              <p>Choose 3 same number all</p>
              <p>
                For all the same three numbers（111、222、…、666）Make an
                all-inclusive bet
              </p>
              <p>Choose 3 same number single</p>
              <p>
                From all the same three numbers（111、…、666）Choose a group of
                numbers in any of them to place bets
              </p>
              <p>Choose 2 Same Multiple</p>
              <p>
                Place a bet on two designated same numbers and an arbitrary
                number among the three numbers
              </p>
              <p>Choose 2 Same Single</p>
              <p>
                Place a bet on two designated same numbers and a designated
                different number among the three numbers
              </p>
              <p>3 numbers different</p>
              <p>Place a bet on three different numbers</p>
              <p>2 numbers different</p>
              <p>
                Place a bet on two designated different numbers and an arbitrary
                number among the three numbers
              </p>
              <p>Choose 3 Consecutive number all</p>
              <p>
                For all three consecutive numbers（123、234、345、456）Place a
                bet
              </p>
              <p>Description of winning and odds:</p>
              <p>Sum Value</p>
              <p>A bet with the same opening number and value is the winning</p>
              <p>Choose 3 same number all</p>
              <p>
                If the opening numbers are any three of the same number, it is
                the winning
              </p>
              <p>Choose 3 same number single</p>
              <p>
                A bet that is exactly the same as the opening number is the
                winning
              </p>
              <p>Choose 2 Same Multiple</p>
              <p>
                The same number as the two same numbers in the opening number
                (except for the three same numbers) is the winning
              </p>
              <p>Choose 2 Same Single</p>
              <p>
                A bet that is exactly the same as the opening number is the
                winning
              </p>
              <p>3 numbers different</p>
              <p>
                A bet that is exactly the same as the opening number is the
                winning
              </p>
              <p>2 numbers different</p>
              <p>
                The same as the two arbitrary numbers in the opening number is
                the winning
              </p>
              <p>Choose 3 Consecutive number all</p>
              <p>
                If the opening numbers are any three consecutive numbers, it is
                the winning
              </p>
            </div>
            <div className="model-policy-footer">
              <div
                className="i-know-btn"
                onClick={() => {
                  $("#howtoPlayModelK3").addClass("hide");
                }}
              >
                Close
              </div>
            </div>
          </div>
        </div>
        <div className="model" id="howtoPlayModelTrx">
          <div className="model-container">
            <div className="model-policy-header">
              <span>How to play</span>
            </div>
            <div className="model-policy-content">
              <p>
                <font face="Verdana">What is a hash value?</font>
              </p>
              <p>
                <font face="Verdana">
                  A person of Bitcoin's fundamental value is exposed to one
                  knowledge, one hash value.
                </font>
              </p>
              <p>
                <font face="Verdana">
                  Hash is a value algorithm calculated with a hash function (or
                  hash function/hash), and we can also translate it into a hash,
                  so the hash value is also called a hash value. . To understand
                  hash values, you must understand hash functions. A hash
                  function can computationally transform an input of arbitrary
                  length into an output of fixed length.
                </font>
              </p>
              <p>
                <font face="Verdana">
                  Functions all have the property that if the input value is the
                  same, the output hash value is the same. If the input value is
                  different, the output hash value is usually different, but if
                  the event is extremely small. If the hash value is solved when
                  the input value changes, the hash function has a hash value
                  that is non-reversible and easy to verify, and if there is
                  indeed a derived value, if it is possible to achieve the hash
                  value of the output input value, you can Hash value for
                  immediate verification.
                </font>
              </p>
              <p>
                <font face="Verdana">
                  The value of each block is unique, eternal and unrevealable,
                  undeniable at the time, the awards circulating in the
                  blockchain are automatically tampered with, and the records
                  cannot be tampered with.
                </font>
              </p>
              <p>
                <br />
              </p>
              <p>
                <font face="Verdana">USDT have how many type?</font>
              </p>
              <p>
                <font face="Verdana">
                  1) Based on Bitcoin platform Omni-USDT, deposit address is
                  Bitcoin address, withdrawal is bitcoin network.
                </font>
              </p>
              <p>
                <font face="Verdana">
                  2)Bases on Ethereum platform ERC20 protocol, deposit address
                  is based on ETH address, withdrawal also based on ETH network.
                </font>
              </p>
              <p>
                <font face="Verdana">
                  3)Based on TRC20-USDT, TRC20 protocal and TRX Network, deposit
                  address using TRON address, withdrawal using TRON network.
                </font>
              </p>
              <p>
                <font face="Verdana">
                  Trx WinGo is based on TRC20 Protocal and TRX network
                  (TRC20-USDT) Block hash last 1 digit to giving the result,
                  (Can click the Block Height to check the Block Hash)
                </font>
              </p>
              <p>
                <br />
              </p>
              <p>
                <font face="Verdana">How To Play:</font>
              </p>
              <p>
                <font face="Verdana">
                  1)1 minute 1 game, within 55 second need to place bet, before
                  5 second unable to place bet
                </font>
              </p>
              <p>
                <font face="Verdana">
                  2)After the betting close, latest has value will be the result
                </font>
              </p>
              <p>
                <font face="Verdana">
                  3)Bet for whole day, One day total bet is 1440 time.
                </font>
              </p>
              <p>
                <font face="Verdana">
                  4)If you bet of 100, will deduct 2 fee, so your betting amount
                  will be 98
                </font>
              </p>
              <p>
                <font face="Verdana">
                  5)3 minute 1 time, 5 minute 1 time, 10 minute 1 time rule same
                  as the 1 minute 1 time, except open result time not same.
                </font>
              </p>
              <p>
                <font face="Verdana">
                  6)Block hash last digit will be the result:
                </font>
              </p>
              <p>
                <font face="Verdana">Example:</font>
              </p>
              <p>
                <font face="Verdana">Hash Value **b569, result will be 9</font>
              </p>
              <p>
                <font face="Verdana">Hash Value **d14c, result will be 4</font>
              </p>
              <p>
                <font face="Verdana">
                  <br />
                </font>
              </p>
              <p>
                <font face="Verdana">
                  -When you bet on Green: If the result either 1,3,7,9, you will
                  get (98*2)=196, if the result is 5, you will get (98*1.5)=147
                </font>
              </p>
              <p>
                <font face="Verdana">
                  -When you bet on Red: If the result either 2,4,6,8, you will
                  get (98*2)=196, if the result is 0, you will get (98*1.5)=147
                </font>
              </p>
              <p>
                <font face="Verdana">
                  -When you bet on Purple/Violet: If the result either 0 or 5,
                  you will get (98*4.5)=441
                </font>
              </p>
              <p>
                <font face="Verdana">
                  When you bet on Number bet: When the result is same as your
                  bet, you will get (98*9)882
                </font>
              </p>
              <p>
                <font face="Verdana">
                  -When you bet on Big: When the result showing either
                  5,6,7,8,9, you will get (98x2)=196
                </font>
              </p>
              <p>
                <font face="Verdana">
                  -When you bet on Small: When the result showing either
                  0,1,2,3,4, you will get (98x2)=196
                </font>
              </p>
            </div>
            <div className="model-policy-footer">
              <div
                className="i-know-btn"
                onClick={() => {
                  $("#howtoPlayModelTrx").addClass("hide");
                }}
              >
                Close
              </div>
            </div>
          </div>
        </div>
      </div>
    </HashRouter>
  );
};

export default App;
