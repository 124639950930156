import React, { useState, useEffect, useRef } from "react";
import $ from "jquery";
import socket from "../../../../socket";
import {
  Capitalize,
  formatDate,
  formatMoney,
  getWingoResult,
} from "../../../../utils/Utils";
import {
  ballNum0,
  ballNum1,
  ballNum2,
  ballNum3,
  ballNum4,
  ballNum5,
  ballNum6,
  ballNum7,
  ballNum8,
  ballNum9,
  notFoundImg,
  checkImg,
  checkCircle,
  lowAudio,
  highAudio,
  noData,
} from "../../../../assets/media";
import { getWingoList } from "../../../../utils/DataController";
import { betWingo } from "../../../../utils/BetController";
import {
  getUser,
  getUserBetHistory,
  getUserWinStatus,
} from "../../../../utils/UserController";
import Toast from "../../Toast";

const Wingo3 = ({ xmoney, user_id, updateUser, showResult }) => {
  const [money, setMoney] = useState(0);
  const [activeTab, setActiveTab] = useState("Game History");
  const [bg, setBg] = useState("");
  const [agree, setAgree] = useState(true);
  const [betType, setBetType] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [mltValue, setMltValue] = useState(1);
  const [betAmount, setBetAmount] = useState(1);
  const [balance, setBalance] = useState(1);
  const [wingoList, setWingoList] = useState([]);
  const [nextPreiod, setNextPreiod] = useState(20240000000);
  const [triggerEffect, setTriggerEffect] = useState(false);
  const [randomIndex, setRandomIndex] = useState(null);
  const [loaded, setLoaded] = useState(false);
  const [timer, setTimer] = useState(0);
  const [bet, setBet] = useState(null);
  const low_audio = new Audio(lowAudio);
  const high_audio = new Audio(highAudio);
  const [toast, setToast] = useState({
    isVisible: false,
    message: "",
  });
  const showToast = (message) => {
    setToast({ isVisible: true, message });
    setTimeout(() => {
      setToast({ isVisible: false, message: "" });
    }, 3000); // Hide after 3 seconds
  };

  const fetchWingoList = async (page) => {
    try {
      const response = await getWingoList(page, "3min");
      if (response.status === 200) {
        setWingoList(response.data.data);
        if (page === 0) {
          setNextPreiod(response.data.data[0].period_id);
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  const fetchUserWinStatus = async () => {
    try {
      const response = await getUserWinStatus("wingo", "3min");
      const status = response.data.data.win_status;
      const betData = response.data.data.bet_data;
      const data = {
        status: status,
        type: "3min",
        period_id: response.data.data.period_id,
        amount: response.data.data.amount,
        win_amount: response.data.data.result_amount,
        color: betData.color_result,
        size: betData.size_result,
        number: betData.num_result,
      };

      showResult(data);
    } catch (err) {
      console.log(err);
    }
  };

  const refreshUserData = async () => {
    const userData = await getUser();
    if (userData.status === 200) {
      updateUser(userData);
      setMoney(userData.data.data.money);
    }
  };

  const randomize = () => {
    const interval = setInterval(() => {
      const newIndex = Math.floor(Math.random() * 10);
      setRandomIndex(newIndex);
    }, 100);

    const timeout = setTimeout(() => {
      clearInterval(interval);
      const newIndex = Math.floor(Math.random() * 10);

      if (newIndex === 0) {
        toggleOffCanvas(newIndex, "bg-rv");
      } else if (newIndex === 5) {
        toggleOffCanvas(newIndex, "bg-gv");
      } else if (newIndex % 2 === 0) {
        toggleOffCanvas(newIndex, "bg-red");
      } else {
        toggleOffCanvas(newIndex, "bg-green");
      }
    }, 3000);

    return () => {
      clearInterval(interval);
      clearTimeout(timeout);
    };
  };

  const toggleOffCanvas = (bet, color) => {
    setBg(color);
    if (color) {
      $(".wingo-model-content1").addClass(color);
      setBet(color);
    }
    if (bet) {
      setBetType(Capitalize(bet.toString()));
      setBet(bet);
    }
    setIsOpen(!isOpen);
    if (!isOpen) {
      refreshUserData();
      $(".overlay").removeClass("d-none");
      $(".container").css("overflow", "hidden");
    } else {
      $(".overlay").addClass("d-none");
      $(".container").css("overflow", "");
      $(".wingo-model-content1").prop("class", "wingo-model-content1");
    }
  };

  const tabs = [
    {
      label: "Game History",
      content: <GameHistory triggerEffect={triggerEffect} />,
    },
    {
      label: "Chart",
      content: <Chart triggerEffect={triggerEffect} />,
    },
    {
      label: "My History",
      content: <MyHistory triggerEffect={triggerEffect} />,
    },
  ];

  const handleTabClick = (label) => {
    setActiveTab(label);
  };
  const handleInput = (e) => {
    let value = e.target.value;
    if (
      value !== "" &&
      !isNaN(value) &&
      Number(value) >= 1 &&
      Number(value) <= 9999
    ) {
      setMltValue(Number(value));
    }
  };

  const handleMlt = (type) => {
    setMltValue((prevMltValue) => {
      let newValue = type ? prevMltValue + 1 : prevMltValue - 1;
      if (newValue < 1) newValue = 1;
      if (newValue > 9999) newValue = 9999;
      return newValue;
    });
  };
  const handleMlt2 = (value) => {
    setMltValue((prevMltValue) => {
      let newValue = value;
      return newValue;
    });
  };

  const handleBetAmount = (balance, mltValue) => {
    const totalBet = balance * mltValue;
    setBetAmount(totalBet);
  };

  const handleBalance = (value) => {
    setBalance(value);
  };

  const confirmBet = async () => {

    if (!agree) {
      showToast(`<svg xmlns="http://www.w3.org/2000/svg" width="35px" height="35px" viewBox="0 0 24 24"><path fill="#fff" d="M13 2v14.5h-2V2zm-2 17h2.004v2.004H11z"></path></svg>Please check I agree to the pre-sale rules`);
      return;
    }

    if (money < betAmount) {
      showToast(
        `<svg xmlns="http://www.w3.org/2000/svg" width="35px" height="35px" viewBox="0 0 24 24"><path fill="#fff" d="M13 2v14.5h-2V2zm-2 17h2.004v2.004H11z"></path></svg>Insufficient balance`
      );
      return;
    }

    const response = await betWingo(
      "3min",
      user_id,
      bet,
      betAmount,
      Number(nextPreiod) + 1
    );

    if (response.status) {
      refreshUserData();
      setTriggerEffect((prevState) => !prevState);
      showToast(response.data.message);
      setBetAmount(1);
      setMltValue(1);
      setBalance(1);
      toggleOffCanvas(null, null);
    }
  };

  const handleAgree = () => {
    setAgree(!agree);
  };

  const formatTime = (timer) => {
    const minutes = String(Math.floor(timer / 60)).padStart(2, "0");
    const seconds = String(timer % 60).padStart(2, "0");

    // Check if sound is enabled from localStorage
    const soundEnabled = localStorage.getItem("soundEnabled") === "true";

    if (loaded) {
      if (
        minutes.charAt(1) === "0" &&
        seconds.charAt(0) === "0" &&
        Number(seconds.charAt(1)) < 6
      ) {
        $(".bet-section-model").addClass("show");
        $(".bet-section-model").removeClass("hide");

        // Only play sounds if sound is enabled
        if (soundEnabled) {
          if (Number(seconds.charAt(1)) < 6 && Number(seconds.charAt(1)) > 1) {
            low_audio.play();
          }
          if (Number(seconds.charAt(1)) === 1) {
            high_audio.play();
          }
        }
      }

      if (seconds.charAt(0) === "0" && Number(seconds.charAt(1)) === 0) {
        $(".bet-section-model").addClass("hide");
        $(".bet-section-model").removeClass("show");
      }
    }

    return { minutes, seconds };
  };

  const images = [
    { src: ballNum0, bg: "bg-rv" },
    { src: ballNum1, bg: "bg-green" },
    { src: ballNum2, bg: "bg-red" },
    { src: ballNum3, bg: "bg-green" },
    { src: ballNum4, bg: "bg-red" },
    { src: ballNum5, bg: "bg-gv" },
    { src: ballNum6, bg: "bg-red" },
    { src: ballNum7, bg: "bg-green" },
    { src: ballNum8, bg: "bg-red" },
    { src: ballNum9, bg: "bg-green" },
  ];

  useEffect(() => {
    setMoney(xmoney);
    fetchWingoList(0);
  }, []);

  useEffect(() => {
    handleBetAmount(balance, mltValue);
  }, [balance, mltValue]);

  useEffect(() => {
    socket.on("timer", (data) => {
      setTimer(data.timer3);
      if (data.timer3 < 6) {
        setIsOpen(false);
        $(".overlay").addClass("d-none");
      $(".container").css("overflow", "");
      }
      if (data.timer3 === 179) {
        setTriggerEffect((prevState) => !prevState);
        refreshUserData();
        fetchWingoList(0);
        fetchUserWinStatus();
      }
      setLoaded(true);
    });

    return () => {
      socket.off("timer");
    };
  }, []);

  const { minutes, seconds } = formatTime(timer);
  return (
    <>
      <div className="wingo-timer">
        <div className="preiod">
          <div
            className="htp"
            onClick={() => {
              $("#howtoPlayModel").removeClass("hide");
              $("#howtoPlayModel").addClass("show");
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="15px"
              height="15px"
              viewBox="0 0 24 24"
            >
              <path
                fill="#fff"
                d="M14.727 6.727H14V0H4.91c-.905 0-1.637.732-1.637 1.636v20.728c0 .904.732 1.636 1.636 1.636h14.182c.904 0 1.636-.732 1.636-1.636V6.727zm-.545 10.455H7.09v-1.364h7.09v1.364zm2.727-3.273H7.091v-1.364h9.818zm0-3.273H7.091V9.273h9.818zM14.727 6h6l-6-6z"
              />
            </svg>
            <span>How to play</span>
          </div>
          <span className="m-text">Win Go 3Min</span>
          <div className="result-balls">
            {wingoList.length > 0
              ? wingoList
                  .slice(0, 5)
                  .map((item, index) => (
                    <img
                      key={index}
                      src={images[item.num_result].src}
                      alt={item.num_result}
                    />
                  ))
              : [0, 0, 0, 0, 0].map((item, index) => (
                  <img key={index} src={images[item].src} alt={item} />
                ))}
          </div>
        </div>
        <div className="timer">
          <span className="text">Time remaining</span>
          <div className="counter-container">
            <span className="counter-num">{minutes.charAt(0)}</span>
            <span className="counter-num">{minutes.charAt(1)}</span>
            <span className="counter-num">:</span>
            <span className="counter-num">{seconds.charAt(0)}</span>
            <span className="counter-num">{seconds.charAt(1)}</span>
          </div>
          <span className="preiod-id">{Number(nextPreiod) + 1}</span>
        </div>
      </div>
      <div className="bet-section">
        <div className="bet-section-main">
          <div className="color-bet-container">
            <span
              className="color-bet bg-green rx"
              onClick={() => toggleOffCanvas("green", "bg-green")}
            >
              Green
            </span>
            <span
              className="color-bet bg-violet fx"
              onClick={() => toggleOffCanvas("violet", "bg-violet")}
            >
              Violet
            </span>
            <span
              className="color-bet bg-red lx"
              onClick={() => toggleOffCanvas("red", "bg-red")}
            >
              Red
            </span>
          </div>
          <div className="balls">
            {images.map((image, index) => (
              <img
                key={index}
                src={image.src}
                onClick={() => toggleOffCanvas(index.toString(), image.bg)}
                alt={index}
                className={index === randomIndex ? "randomize" : ""}
              />
            ))}
          </div>
          <div className="x-multiply">
            <span className="random" onClick={randomize}>
              Random
            </span>
            {[1, 5, 10, 20, 50, 100].map((multiplier) => (
              <div
                key={multiplier}
                className={`x-box ${
                  mltValue === multiplier ? "active " + bg : ""
                }`}
                onClick={() => handleMlt2(multiplier)}
              >
                X{multiplier}
              </div>
            ))}
          </div>
          <div className="size-bet">
            <div
              className="big bg-big"
              onClick={() => toggleOffCanvas("big", "bg-big")}
            >
              Big
            </div>
            <div
              className="small bg-small"
              onClick={() => toggleOffCanvas("small", "bg-small")}
            >
              Small
            </div>
          </div>
        </div>
        <div className="bet-section-model">
          <div className="counter1">{seconds.charAt(0)}</div>
          <div className="counter2">{seconds.charAt(1)}</div>
        </div>
      </div>
      <div className="history-tabs">
        {tabs.map((tab, index) => (
          <div
            key={index}
            className={`history-tab ${activeTab === tab.label ? "active" : ""}`}
            onClick={() => handleTabClick(tab.label)}
          >
            {tab.label}
          </div>
        ))}
      </div>
      <div className="history-tab-content">
        {tabs.find((tab) => tab.label === activeTab)?.content}
      </div>
      <div className={`off-canvas ${isOpen ? "open" : ""}`}>
        <div className="off-canvas-container">
          <div className="off-canvas-content">
            <div className="wingo-model-content1">
              <div className="content-1">
                <span className="game-name">Win Go 3Min</span>
                <div className="select-box">
                  <span>Select</span>
                  <span>{betType}</span>
                </div>
              </div>
            </div>
            <div className="content2">
              <div className="content2-balance">
                <span>Balance</span>
                <div className="d-flex">
                  {[1, 10, 100, 1000].map((val) => (
                    <div
                      key={val}
                      className={`x-box ${
                        balance === val ? "active " + bg : ""
                      }`}
                      onClick={() => handleBalance(val)}
                    >
                      {val}
                    </div>
                  ))}
                </div>
              </div>
              <div className="content2-qty">
                <span>Quantity</span>
                <div className="d-flex">
                  <div
                    className={`x-box btn ${bg}`}
                    onClick={() => handleMlt(false)}
                  >
                    -
                  </div>
                  <div className="x-box mlt">
                    <input
                      type="number"
                      className="mlt-input"
                      value={mltValue}
                      readOnly={true}
                      onChange={handleInput}
                      min="1"
                      max="9999"
                    />
                  </div>
                  <div
                    className={`x-box btn ${bg}`}
                    onClick={() => handleMlt(true)}
                  >
                    +
                  </div>
                </div>
              </div>
              <div className="content2-mlt">
                <div className="d-flex">
                  {[1, 5, 10, 20, 50, 100].map((multiplier) => (
                    <div
                      key={multiplier}
                      className={`x-box ${
                        mltValue === multiplier ? "active " + bg : ""
                      }`}
                      onClick={() => handleMlt2(multiplier)}
                    >
                      X{multiplier}
                    </div>
                  ))}
                </div>
              </div>
              <div className="content2-policy">
                <div className="d-flex align-center" style={{ gap: "10px" }}>
                  <div
                    className="d-flex align-center"
                    style={{ gap: "10px" }}
                    onClick={handleAgree}
                  >
                    <img
                      src={agree ? checkImg : checkCircle}
                      alt=""
                      className="check-img"
                    />
                    <span>I agree</span>
                  </div>
                  <span
                    className="policy-btn"
                    onClick={() => {
                      $("#agreeModel").removeClass("hide");
                      $("#agreeModel").addClass("show");
                    }}
                  >
                    《Pre-sale rules》
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="off-canvas-footer">
            <div className="cancel" onClick={() => toggleOffCanvas(null, null)}>
              Cancel
            </div>
            <div className={`amt ${bg}`} onClick={confirmBet}>
              Total amount {formatMoney(betAmount)}
            </div>
          </div>
        </div>
      </div>
      <Toast
        message={toast.message}
        isVisible={toast.isVisible}
        sm={true}
        onClose={() => setToast({ isVisible: false, message: "" })}
      />
    </>
  );
};

export default Wingo3;

const GameHistory = ({ triggerEffect }) => {
  const [wingoList, setWingoList] = useState([]);
  const [pageNo, setPageNo] = useState(0);
  const [pageCount, setPageCount] = useState(0);

  const fetchWingoList = async (page) => {
    try {
      const response = await getWingoList(page, "3min");
      if (response.status === 200) {
        setWingoList(response.data.data);
        setPageCount(response.data.totalPageCount);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const nextPage = () => {
    if (pageCount === pageNo) {
      return;
    }
    setPageNo((prevPageNo) => prevPageNo + 1);
    fetchWingoList(pageNo + 1);
  };

  const prevPage = () => {
    if (pageNo <= 0) {
      return;
    }
    setPageNo((prevPageNo) => prevPageNo - 1);
    fetchWingoList(pageNo - 1);
  };
  const renderCircles = (colorResult) => {
    const colors = colorResult.split(",");
    return (
      <>
        {colors.map((color, index) => (
          <div
            key={index}
            className={`circle circle-${color.trim()} me-1`}
          ></div>
        ))}
      </>
    );
  };
  const getNumClass = (numResult, color) => {
    if (numResult === "0") {
      return "text-rv";
    } else if (numResult === "5") {
      return "text-gv";
    } else {
      return `text-${color}`;
    }
  };

  useEffect(() => {
    fetchWingoList(0);
  }, [triggerEffect]);
  return (
    <>
      <div className="game-hsitory">
        <div className="game-history-header">
          <span>Period</span>
          <span>Number</span>
          <span>Big Small</span>
          <span>Color</span>
        </div>
        <div className="game-history-content">
          {wingoList.map((item, index) => (
            <div className="history-item" key={index}>
              <span>{item.period_id}</span>
              <span className={getNumClass(item.num_result, item.color_result)}>
                {item.num_result}
              </span>
              <span>{item.size_result}</span>
              <span>{renderCircles(item.color_result)}</span>
            </div>
          ))}
        </div>
      </div>
      <div className="game-history-footer">
        <div
          className={`prev ${pageNo > 0 ? "active" : ""}`}
          onClick={() => prevPage()}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="25px"
            height="25px"
            viewBox="0 0 24 24"
          >
            <path
              fill="#768096"
              d="M13.83 19a1 1 0 0 1-.78-.37l-4.83-6a1 1 0 0 1 0-1.27l5-6a1 1 0 0 1 1.54 1.28L10.29 12l4.32 5.36a1 1 0 0 1-.78 1.64"
            />
          </svg>
        </div>
        <div className="pages">
          <span>{pageNo + 1}</span>/<span>{pageCount + 1}</span>
        </div>
        <div
          className={`next ${pageNo < pageCount ? "active" : ""}`}
          onClick={() => nextPage()}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="25px"
            height="25px"
            viewBox="0 0 24 24"
          >
            <path
              fill="#fff"
              d="M10 19a1 1 0 0 1-.64-.23a1 1 0 0 1-.13-1.41L13.71 12L9.39 6.63a1 1 0 0 1 .15-1.41a1 1 0 0 1 1.46.15l4.83 6a1 1 0 0 1 0 1.27l-5 6A1 1 0 0 1 10 19"
            />
          </svg>
        </div>
      </div>
    </>
  );
};

const Chart = ({ triggerEffect }) => {
  const canvasRef = useRef("null");
  const [wingoList, setWingoList] = useState([]);
  const [pageNo, setPageNo] = useState(0);
  const [pageCount, setPageCount] = useState(0);

  const fetchWingoList = async (page) => {
    try {
      const response = await getWingoList(page, "3min");
      if (response.status === 200) {
        setWingoList(response.data.data);
        setPageCount(response.data.totalPageCount);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const nextPage = () => {
    if (pageCount === pageNo) {
      return;
    }
    setPageNo((prevPageNo) => prevPageNo + 1);
    fetchWingoList(pageNo + 1);
  };

  const prevPage = () => {
    if (pageNo <= 0) {
      return;
    }
    setPageNo((prevPageNo) => prevPageNo - 1);
    fetchWingoList(pageNo - 1);
  };

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");

    // Clear the canvas
    ctx.clearRect(0, 0, canvas.width, canvas.height);

    const connectElements = document.querySelectorAll(".connect");
    if (connectElements.length > 1) {
      ctx.beginPath();
      ctx.strokeStyle = "#d30095";
      ctx.lineWidth = 1;

      connectElements.forEach((elem, index) => {
        const rect = elem.getBoundingClientRect();
        const canvasRect = canvas.getBoundingClientRect();
        const x = rect.left + rect.width / 2 - canvasRect.left;
        const y = rect.top + rect.height / 2 - canvasRect.top;

        if (index === 0) {
          ctx.moveTo(x, y);
        } else {
          ctx.lineTo(x, y);
        }
      });

      ctx.stroke();
    }
  }, [wingoList]);

  useEffect(() => {
    fetchWingoList(0);
  }, [triggerEffect]);

  return (
    <>
      <div className="chart-history">
        <div className="chart-history-header">
          <span>Period</span>
          <span>Number</span>
        </div>
        <div className="chart-item-first">
          <div className="chart-item-first-content">
            Statistic (last 100 Periods)
          </div>
          <div className="chart-item-first-content mt-1">
            <span>Winning number</span>
            <span>
              <div className="nums">
                <div>0</div>
                <div>1</div>
                <div>2</div>
                <div>3</div>
                <div>4</div>
                <div>5</div>
                <div>6</div>
                <div>7</div>
                <div>8</div>
                <div>9</div>
              </div>
            </span>
          </div>
          <div className="chart-item-first-content mt-1">
            <span>Missing</span>
            <span>
              <div className="nums x">
                <div>8</div>
                <div>1</div>
                <div>12</div>
                <div>3</div>
                <div>41</div>
                <div>5</div>
                <div>16</div>
                <div>7</div>
                <div>81</div>
                <div>91</div>
              </div>
            </span>
          </div>
          <div className="chart-item-first-content mt-1">
            <span>Avarage missing</span>
            <span>
              <div className="nums x">
                <div>11</div>
                <div>1</div>
                <div>12</div>
                <div>3</div>
                <div>71</div>
                <div>5</div>
                <div>36</div>
                <div>74</div>
                <div>61</div>
                <div>39</div>
              </div>
            </span>
          </div>
          <div className="chart-item-first-content mt-1">
            <span>Frequency</span>
            <span>
              <div className="nums x">
                <div>3</div>
                <div>7</div>
                <div>4</div>
                <div>3</div>
                <div>21</div>
                <div>45</div>
                <div>76</div>
                <div>7</div>
                <div>23</div>
                <div>56</div>
              </div>
            </span>
          </div>
          <div className="chart-item-first-content mt-1">
            <span>Max consecutive</span>
            <span>
              <div className="nums x">
                <div>2</div>
                <div>1</div>
                <div>12</div>
                <div>3</div>
                <div>42</div>
                <div>5</div>
                <div>11</div>
                <div>7</div>
                <div>21</div>
                <div>23</div>
              </div>
            </span>
          </div>
        </div>
        <div className="chart-history-content">
          <canvas
            ref={canvasRef}
            width={800}
            height={500}
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              zIndex: 1,
            }}
          ></canvas>
          {wingoList.map((item, index) => (
            <div className="chart-item" key={index}>
              <span>{item.period_id}</span>
              <span>
                <div className="nums">
                  <div
                    className={
                      item.num_result === "0" ? "c0 connect bg-rv" : ""
                    }
                  >
                    0
                  </div>
                  <div className={item.num_result === "1" ? "c1 connect" : ""}>
                    1
                  </div>
                  <div className={item.num_result === "2" ? "c2 connect" : ""}>
                    2
                  </div>
                  <div className={item.num_result === "3" ? "c3 connect" : ""}>
                    3
                  </div>
                  <div className={item.num_result === "4" ? "c4 connect" : ""}>
                    4
                  </div>
                  <div
                    className={
                      item.num_result === "5" ? "c5 connect bg-gv" : ""
                    }
                  >
                    5
                  </div>
                  <div className={item.num_result === "6" ? "c6 connect" : ""}>
                    6
                  </div>
                  <div className={item.num_result === "7" ? "c7 connect" : ""}>
                    7
                  </div>
                  <div className={item.num_result === "8" ? "c8 connect" : ""}>
                    8
                  </div>
                  <div className={item.num_result === "9" ? "c9 connect" : ""}>
                    9
                  </div>
                </div>
                <div
                  className={`size-num ${
                    item.size_result === "big" ? "bg-big" : "bg-small"
                  } text-white`}
                >
                  {item.size_result === "big" ? "B" : "S"}
                </div>
              </span>
            </div>
          ))}
        </div>
      </div>
      <div className="chart-history-footer">
        <div
          className={`prev ${pageNo > 0 ? "active" : ""}`}
          onClick={prevPage}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="25px"
            height="25px"
            viewBox="0 0 24 24"
          >
            <path
              fill="#768096"
              d="M13.83 19a1 1 0 0 1-.78-.37l-4.83-6a1 1 0 0 1 0-1.27l5-6a1 1 0 0 1 1.54 1.28L10.29 12l4.32 5.36a1 1 0 0 1-.78 1.64"
            />
          </svg>
        </div>
        <div className="pages">
          <span>{pageNo + 1}</span>/<span>{pageCount + 1}</span>
        </div>
        <div
          className={`next ${pageNo < pageCount ? "active" : ""}`}
          onClick={nextPage}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="25px"
            height="25px"
            viewBox="0 0 24 24"
          >
            <path
              fill="#fff"
              d="M10 19a1 1 0 0 1-.64-.23a1 1 0 0 1-.13-1.41L13.71 12L9.39 6.63a1 1 0 0 1 .15-1.41a1 1 0 0 1 1.46.15l4.83 6a1 1 0 0 1 0 1.27l-5 6A1 1 0 0 1 10 19"
            />
          </svg>
        </div>
      </div>
    </>
  );
};

const MyHistory = ({ triggerEffect }) => {
  const [history, setHistory] = useState([]);
  const [pageNo, setPageNo] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const fetchMyHistory = async (page) => {
    try {
      const response = await getUserBetHistory(page, "wingo", "3min");
      if (response.status === 200) {
        setHistory(response.data.data);
        setPageCount(response.data.totalPageCount);
      }
    } catch (err) {
      console.log(err);
    }
  };
  const nextPage = () => {
    if (pageCount === pageNo) {
      return;
    }
    setPageNo((prevPageNo) => prevPageNo + 1);
    fetchMyHistory(pageNo + 1);
  };

  const prevPage = () => {
    if (pageNo <= 0) {
      return;
    }
    setPageNo((prevPageNo) => prevPageNo - 1);
    fetchMyHistory(pageNo - 1);
  };
  const [expandedIndex, setExpandedIndex] = useState(null);

  const handleToggle = (index) => {
    setExpandedIndex(expandedIndex === index ? null : index);
  };
  useEffect(() => {
    fetchMyHistory(0);
  }, [triggerEffect]);
  return (
    <>
      <div className="my-history">
        <div className="my-history-details">
          <div className="details-btn">
            Details
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20px"
              height="20px"
              viewBox="0 0 512 512"
            >
              <path
                fill="none"
                stroke="var(--main-color)"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="32"
                d="M262.62 336L342 256l-79.38-80m68.35 80H170"
              />
              <path
                fill="none"
                stroke="var(--main-color)"
                strokeMiterlimit="10"
                strokeWidth="32"
                d="M256 448c106 0 192-86 192-192S362 64 256 64S64 150 64 256s86 192 192 192Z"
              />
            </svg>
          </div>
        </div>
        <div className="my-history-content">
          {history.length > 0 ? (
            history.map((item, index) => (
              <div
                className="my-history-item"
                key={index}
                onClick={() => handleToggle(index)}
              >
                <div
                  className="d-flex align-center justify-between"
                  style={{ width: "100%", paddingBottom: "20px" }}
                >
                  <div className="d-flex align-center" style={{ gap: "10px" }}>
                    {isNaN(item.bet) ? (
                      <div className={`bet-span bg-${item.bet}`}>
                        {item.bet}
                      </div>
                    ) : (
                      <div
                        className={`bet-span bg-${
                          Number(item.bet) === 0
                            ? "rv"
                            : Number(item.bet) === 5
                            ? "gv"
                            : Number(item.bet) % 2 === 0
                            ? "red"
                            : "green"
                        }`}
                      >
                        {item.bet}
                      </div>
                    )}
                    <div
                      className="d-flex flex-col"
                      style={{ alignItems: "start", gap: "10px" }}
                    >
                      <span>{item.period_id}</span>
                      <span style={{ fontSize: ".8rem", color: "#768096" }}>
                        {formatDate("d-m-Y h:M:S A", item.order_time)}
                      </span>
                    </div>
                  </div>
                  <div className="win-status">
                    {item.win_status === "win" ? (
                      <>
                        <div
                          className="status-box"
                          style={{ color: "#00ae50", borderColor: "#00ae50" }}
                        >
                          {"Succeed"}
                        </div>
                        <div
                          className="result-amt"
                          style={{ color: "#00ae50" }}
                        >
                          +{formatMoney(item.result_amount)}
                        </div>
                      </>
                    ) : (
                      <>
                        {item.win_status === "loss" ? (
                          <>
                            <div
                              className="status-box"
                              style={{
                                color: "var(--main-color)",
                                borderColor: "var(--main-color)",
                              }}
                            >
                              {"Failed"}
                            </div>
                            <div
                              className="result-amt"
                              style={{ color: "var(--main-color)" }}
                            >
                              -{formatMoney(item.Amount_after_tax)}
                            </div>
                          </>
                        ) : (
                          ""
                        )}
                      </>
                    )}
                  </div>
                </div>
                <div
                  className={`history-details ${
                    expandedIndex === index ? "show" : ""
                  }`}
                >
                  <span style={{ fontSize: "1.2rem" }}>Details</span>
                  {Object.keys(item).map((key, i) => (
                    <div className="details-item" key={i}>
                      <span style={{ textTransform: "capitalize" }}>
                        {key.replace("_", " ")}
                      </span>
                      <span>
                        {key === "order_time"
                          ? formatDate("Y-m-d h:M:S A", item[key])
                          : item[key]}
                      </span>
                    </div>
                  ))}
                </div>
              </div>
            ))
          ) : (
            <div className="empty">
              <img src={noData} alt="" className="noData" />
              <span>No Data</span>
            </div>
          )}
        </div>
      </div>
      {history.length > 0 ? (
        <div className="my-history-footer">
          <div
            className={`prev ${pageNo > 0 ? "active" : ""}`}
            onClick={prevPage}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="25px"
              height="25px"
              viewBox="0 0 24 24"
            >
              <path
                fill="#768096"
                d="M13.83 19a1 1 0 0 1-.78-.37l-4.83-6a1 1 0 0 1 0-1.27l5-6a1 1 0 0 1 1.54 1.28L10.29 12l4.32 5.36a1 1 0 0 1-.78 1.64"
              />
            </svg>
          </div>
          <div className="pages">
            <span>{pageNo + 1}</span>/
            <span>{pageCount > 0 ? pageCount + 1 : 1}</span>
          </div>
          <div
            className={`next ${pageNo < pageCount ? "active" : ""}`}
            onClick={nextPage}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="25px"
              height="25px"
              viewBox="0 0 24 24"
            >
              <path
                fill="#fff"
                d="M10 19a1 1 0 0 1-.64-.23a1 1 0 0 1-.13-1.41L13.71 12L9.39 6.63a1 1 0 0 1 .15-1.41a1 1 0 0 1 1.46.15l4.83 6a1 1 0 0 1 0 1.27l-5 6A1 1 0 0 1 10 19"
              />
            </svg>
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
};
