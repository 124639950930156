import React, { useEffect, useState } from "react";
import CommonHeader from "../../common/CommonHeader";
import { formatDate, showLoader } from "../../../utils/Utils";
import { getNews } from "../../../utils/UserController";

const Notify = () => {
  const [news, setNews] = useState({});

  useEffect(() => {
    const getNewsData = async () => {
      showLoader(true);
      try {
        const res = await getNews();
        setNews(res.data.data);
        showLoader(false);
      } catch (error) {
        console.log(error);
      }
    };
    getNewsData();
  }, []);

  return (
    <div className="container news-container">
      <CommonHeader
        path="/"
        heading="News"
        svg={
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20px"
            height="20px"
            viewBox="0 0 24 24"
          >
            <path
              fill="var(--lightText)"
              d="m3.55 12l7.35 7.35q.375.375.363.875t-.388.875t-.875.375t-.875-.375l-7.7-7.675q-.3-.3-.45-.675T.825 12t.15-.75t.45-.675l7.7-7.7q.375-.375.888-.363t.887.388t.375.875t-.375.875z"
            />
          </svg>
        }
      />
      <div className="msg-content">
        {(Array.isArray(news) ? news : []).map((value, index) => (
          <div className="msg-container" key={index}>
            <div className="d-flex justify-between align-center">
              <div className="d-flex align-center" style={{ gap: "5px" }}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20px"
                  height="20px"
                  viewBox="0 0 1024 1024"
                >
                  <path
                    fill="var(--main-color)"
                    d="M880 112c-3.8 0-7.7.7-11.6 2.3L292 345.9H128c-8.8 0-16 7.4-16 16.6v299c0 9.2 7.2 16.6 16 16.6h101.6c-3.7 11.6-5.6 23.9-5.6 36.4c0 65.9 53.8 119.5 120 119.5c55.4 0 102.1-37.6 115.9-88.4l408.6 164.2c3.9 1.5 7.8 2.3 11.6 2.3c16.9 0 32-14.2 32-33.2V145.2C912 126.2 897 112 880 112M344 762.3c-26.5 0-48-21.4-48-47.8c0-11.2 3.9-21.9 11-30.4l84.9 34.1c-2 24.6-22.7 44.1-47.9 44.1"
                  />
                </svg>
                <span className="msg-heading text-bold">{value.title}</span>
              </div>
            </div>
            <span className="noti-msg" style={{margin: "15px 0px"}}>{value.msg}</span>
            <div className="footer">
              <span className="login-time" style={{ margin: "0" }}>
                {formatDate("Y-m-d h:M A", value.created_at)}
              </span>
              <div className={`status`} style={{ background: "none" }}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="15px"
                  height="15px"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill="var(--main-color)"
                    d="M12 9a3 3 0 0 0-3 3a3 3 0 0 0 3 3a3 3 0 0 0 3-3a3 3 0 0 0-3-3m0 8a5 5 0 0 1-5-5a5 5 0 0 1 5-5a5 5 0 0 1 5 5a5 5 0 0 1-5 5m0-12.5C7 4.5 2.73 7.61 1 12c1.73 4.39 6 7.5 11 7.5s9.27-3.11 11-7.5c-1.73-4.39-6-7.5-11-7.5"
                  ></path>
                </svg>
                <span style={{ color: "grey", fontSize: ".75rem" }}>
                  {value.views}
                </span>
              </div>
            </div>
          </div>
        ))}
      </div>
      <span className="no-more">No more</span>
    </div>
  );
};

export default Notify;

