import React, { useState, useEffect, useRef } from "react";
import $ from "jquery";
import socket from "../../../../socket";
import { formatDate, formatMoney } from "../../../../utils/Utils";
import {
  lowAudio,
  highAudio,
  notFoundImg,
  checkImg,
  checkCircle,
  ludo1,
  ludo2,
  ludo3,
  ludo4,
  ludo5,
  ludo6,
  noData,
} from "../../../../assets/media";
import {
  getUser,
  getUserBetHistory,
  getUserWinStatus,
} from "../../../../utils/UserController";
import { getD5List } from "../../../../utils/DataController";
import { betD5 } from "../../../../utils/BetController";
import "../../../../assets/css/d5.css";
import "../../../../assets/css/wingo.css";

const D51 = ({ xmoney, user_id, updateUser, showResult }) => {
  const [money, setMoney] = useState(0);
  const [activeTab, setActiveTab] = useState("Game History");
  const [betActiveTab, setBetActiveTab] = useState("A");
  const [bg, setBg] = useState("");
  const [agree, setAgree] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const [mltValue, setMltValue] = useState(1);
  const [betAmount, setBetAmount] = useState(1);
  const [balance, setBalance] = useState(1);
  const [wingoList, setWingoList] = useState([]);
  const [nextPreiod, setNextPreiod] = useState(20240000000);
  const [triggerEffect, setTriggerEffect] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [timer, setTimer] = useState(0);
  const [qty2, setQty2] = useState(1);
  const [bet, setBet] = useState(null);
  const [animating, setAnimating] = useState(false);
  const [numbers, setNumbers] = useState([2, 7, 1, 4, 3]);
  const [activeBoxbet, setActiveBoxbet] = useState("");
  const [activeNumbers, setActiveNumbers] = useState([]);
  const [click, setClick] = useState(false);

  const low_audio = new Audio(lowAudio);
  const high_audio = new Audio(highAudio);
  let removeModel;

  const fetchWingoList = async (page) => {
    try {
      const response = await getD5List(page, "10min");
      if (response.status === 200) {
        setWingoList(response.data.data);
        if (page === 0) {
          const numbers = response.data.data[0].multiple_num_result.split(",");
          setNextPreiod(response.data.data[0].period_id);
          setNumbers(numbers);
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

 const fetchUserWinStatus = async () => {
   try {
     const response = await getUserWinStatus("d5", "10min");
     const status = response.data.data.win_status;
     const betData = response.data.data.bet_data;
     const data = {
       status: status,
       type: "1",
       period_id: response.data.data.period_id,
       amount: response.data.data.amount,
       win_amount: response.data.data.result_amount,
       color: betData.ludo_result,
       size: betData.size_result,
       number: betData.num_result,
     };

     showResult(data);
   } catch (err) {
     console.log(err);
   }
 };

  const refreshUserData = async () => {
    const userData = await getUser();
    if (userData.status === 200) {
      updateUser(userData);
      if (userData.data.data) {
        setMoney(userData.data.data.money);
      }
    }
  };

  const randomize = () => {
    setAnimating(true);
    const timeout = setTimeout(() => {
      const fetchData = async () => {
        const response = await getD5List(0, "10min");
        if (response.status === 200) {
          const numbers = response.data.data[0].multiple_num_result.split(",");
          setNumbers(numbers);
        }
      };
      fetchData();
      setTimeout(() => {
        setAnimating(false);
      }, 4000);
    }, 1000);

    return () => {
      clearTimeout(timeout);
    };
  };

  const toggleOffCanvas = (open) => {
    setIsOpen(open);
    setBet({ betActiveTab, activeBoxbet, activeNumbers, qty: mltValue + qty2 });
    if (!open) {
      refreshUserData();
      $(".overlay").addClass("d-none");
      $(".container").css("overflow", "");
      setActiveBoxbet("");
      setActiveNumbers([]);
    } else {
      $(".overlay").removeClass("d-none");
      $(".container").css("overflow", "hidden");
      $(".wingo-model-content1").prop("class", "wingo-model-content1");
    }
  };

  const tabs = [
    {
      label: "Game History",
      content: <GameHistory triggerEffect={triggerEffect} />,
    },
    {
      label: "Chart",
      content: <Chart triggerEffect={triggerEffect} />,
    },
    {
      label: "My History",
      content: <MyHistory triggerEffect={triggerEffect} />,
    },
  ];

  const handleTabClick = (label) => {
    setActiveTab(label);
  };

  const handleBetTabClick = (label) => {
    setBet(null);
    setBetActiveTab(label);
    if (label === "Sum") {
      setActiveBoxbet("");
      setActiveNumbers([]);
      setQty2(1);
      setMltValue(1);
    }
  };

  const handleInput = (e) => {
    let value = e.target.value;
    if (
      value !== "" &&
      !isNaN(value) &&
      Number(value) >= 1 &&
      Number(value) <= 9999
    ) {
      setMltValue(Number(value));
    }
  };

  const handleMlt = (type) => {
    setMltValue((prevMltValue) => {
      let newValue = type ? prevMltValue + 1 : prevMltValue - 1;
      if (newValue < 1) newValue = 1;
      if (newValue > 9999) newValue = 9999;
      return newValue;
    });
  };

  const handleMlt2 = (value) => {
    setMltValue((prevMltValue) => {
      let newValue = value;
      return newValue;
    });
  };

  const handleBetAmount = (balance, mltValue, qty2) => {
    const totalBet = balance * mltValue * qty2;
    setBetAmount(totalBet);
  };

  const handleBalance = (value) => {
    setBalance(value);
  };

  const confirmBet = async () => {
    clearTimeout(removeModel);
    if (!agree && true) {
      $("#smallPopup").addClass("show");
      $("#smallPopup").text("Please check I agree to the pre-sale rules");
      removeModel = setTimeout(
        () => {
          $("#smallPopup").removeClass("show");
        },
        2000,
        () => {
          $("#smallPopup").text("");
        }
      );
      return;
    }

    if (money < betAmount) {
      $("#smallPopup").addClass("show");
      $("#smallPopup").html(
        `<span style="font-size:40px;">!</span><span>Error: 142 </br>Insufficient balance</span>`
      );
      removeModel = setTimeout(
        () => {
          $("#smallPopup").removeClass("show");
        },
        2000,
        () => {
          $("#smallPopup").text("");
        }
      );
      return;
    }

    const response = await betD5(
      "10min",
      user_id,
      bet,
      betAmount,
      Number(nextPreiod) + 1
    );

    if (response.status) {
      refreshUserData();
      setTriggerEffect((prevState) => !prevState);
      $("#smallPopup").addClass("show");
      $("#smallPopup").text(response.data.message);
      toggleOffCanvas(false, null, null);
      removeModel = setTimeout(
        () => {
          $("#smallPopup").removeClass("show");
        },
        2000,
        () => {
          $("#smallPopup").text("");
        }
      );
    }
  };

  const handleAgree = () => {
    setAgree(!agree);
  };

  const formatTime = (timer) => {
    const minutes = String(Math.floor(timer / 60)).padStart(2, "0");
    const seconds = String(timer % 60).padStart(2, "0");

    // Check if sound is enabled from localStorage
    const soundEnabled = localStorage.getItem("soundEnabled") === "true";

    if (loaded) {
      if (
        minutes.charAt(1) === "0" &&
        seconds.charAt(0) === "0" &&
        Number(seconds.charAt(1)) < 6
      ) {
        $(".bet-section-model").addClass("show");
        $(".bet-section-model").removeClass("hide");

        // Only play sounds if sound is enabled
        if (soundEnabled) {
          if (Number(seconds.charAt(1)) < 6 && Number(seconds.charAt(1)) > 1) {
            low_audio.play();
          }
          if (Number(seconds.charAt(1)) === 1) {
            high_audio.play();
          }
        }
      }

      if (seconds.charAt(0) === "3" && Number(seconds.charAt(1)) === 0) {
        $(".bet-section-model").addClass("hide");
        $(".bet-section-model").removeClass("show");
      }
    }

    return { minutes, seconds };
  };

  const bet_tabs = [
    {
      label: "A",
    },
    {
      label: "B",
    },
    {
      label: "C",
    },
    {
      label: "D",
    },
    {
      label: "E",
    },
    {
      label: "Sum",
    },
  ];

  const handleActiveNumbers = (n) => {
    setActiveBoxbet("");
    setActiveNumbers((prevActiveNumbers) => {
      if (prevActiveNumbers.includes(n)) {
        return prevActiveNumbers.filter((number) => number !== n);
      } else {
        return [...prevActiveNumbers, n];
      }
    });
    setClick(!click);
  };

  const handleActiveBoxbet = (bet) => {
    setActiveNumbers([]);
    if (bet === activeBoxbet) {
      setActiveBoxbet("");
    } else {
      setActiveBoxbet(bet);
    }
    setClick(!click);
  };
  const checkBet = () => {
    return activeNumbers.length > 0 || activeBoxbet !== "";
  };

  const nums = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];

  useEffect(() => {
    toggleOffCanvas(checkBet());
    const qty = activeNumbers.length + (activeBoxbet !== "" ? 1 : 0);
    setQty2(qty);
  }, [click]);

  useEffect(() => {
    setMoney(xmoney);
    fetchWingoList(0);
  }, [triggerEffect]);

  useEffect(() => {
    handleBetAmount(balance, mltValue, qty2);
  }, [balance, mltValue, qty2]);

  useEffect(() => {
    socket.on("timer", (data) => {
      setTimer(data.timer10);
      if (data.timer10 < 6) {
        setIsOpen(false);
        $(".overlay").addClass("d-none");
      $(".container").css("overflow", "");
      }
      if (data.timer10 === 599) {
        randomize();
        setTriggerEffect((prevState) => !prevState);
        refreshUserData();
        fetchWingoList(0);
        fetchUserWinStatus();
      }
      setLoaded(true);
    });

    return () => {
      socket.off("timer");
    };
  }, []);

  const { minutes, seconds } = formatTime(timer);

  return (
    <>
      <div className="lottery-result-container">
        <div className="result1">
          <span>Lottery</span>
          <span>results</span>
        </div>
        <div className="result2">
          <div className="num-container">
            {numbers.map((n, i) => (
              <div className="num" key={i}>
                {n}
              </div>
            ))}
            <div className="">=</div>
            <div className="num bg-red" style={{ color: "#fff" }}>
              {numbers.reduce((a, b) => Number(a) + Number(b), 0)}
            </div>
          </div>
          <div className="letter-container">
            <div className="letter">A</div>
            <div className="letter">B</div>
            <div className="letter">C</div>
            <div className="letter">D</div>
            <div className="letter">E</div>
            <div style={{ opacity: "0" }}>=</div>
            <div className="letter"></div>
          </div>
        </div>
      </div>
      <div className="ludo-meter">
        <div
          className="d-flex align-center justify-between"
          style={{ padding: "5px" }}
        >
          <div
            className="d-flex align-center justify-center"
            style={{ gap: "10px" }}
          >
            <span className="period-name">Period</span>
            <div
              className="d-flex align-center justify-center htp"
              onClick={() => {
                $("#howtoPlayModelK3").removeClass("hide");
                $("#howtoPlayModelK3").addClass("show");
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="17px"
                height="17px"
                viewBox="0 0 24 24"
              >
                <path
                  fill="var(--main-color)"
                  d="M14.727 6.727H14V0H4.91c-.905 0-1.637.732-1.637 1.636v20.728c0 .904.732 1.636 1.636 1.636h14.182c.904 0 1.636-.732 1.636-1.636V6.727zm-.545 10.455H7.09v-1.364h7.09v1.364zm2.727-3.273H7.091v-1.364h9.818zm0-3.273H7.091V9.273h9.818zM14.727 6h6l-6-6z"
                />
              </svg>
              <span>How to play</span>
            </div>
          </div>
          <span className="tr">Time Remaining</span>
        </div>
        <div
          className="d-flex align-center justify-between"
          style={{ padding: "5px" }}
        >
          <span className="preiod-id">{Number(nextPreiod) + 1}</span>
          <div className="k3-counter-container">
            <span className="counter-num">{minutes.charAt(0)}</span>
            <span className="counter-num">{minutes.charAt(1)}</span>
            <span className="counter-num x">:</span>
            <span className="counter-num">{seconds.charAt(0)}</span>
            <span className="counter-num">{seconds.charAt(1)}</span>
          </div>
        </div>
        <div className="d-flex align-center justify-between pt-2">
          <div className="meter" onClick={randomize}>
            <div className="d5-meter-inner">
              {numbers.map((num, index) => (
                <div
                  className={`num ${index === 0 ? "active" : ""}`}
                  key={index}
                >
                  <div
                    className={`meter-num-container ${animating ? "roll" : ""}`}
                    style={{ animationDelay: `${index * 0.2}s` }}
                  >
                    <div className="meter-num">0</div>
                    <div className="meter-num">{numbers[index]}</div>
                    <div className="meter-num">2</div>
                    <div className="meter-num">3</div>
                    <div className="meter-num">4</div>
                    <div className="meter-num">5</div>
                    <div className="meter-num">6</div>
                    <div className="meter-num">7</div>
                    <div className="meter-num">8</div>
                    <div className="meter-num">9</div>
                    <div className="meter-num">0</div>
                    <div className="meter-num">1</div>
                    <div className="meter-num">2</div>
                    <div className="meter-num">3</div>
                    <div className="meter-num">4</div>
                    <div className="meter-num">5</div>
                    <div className="meter-num">6</div>
                    <div className="meter-num">7</div>
                    <div className="meter-num">8</div>
                    <div className="meter-num">{numbers[index]}</div>
                    <div className="meter-num">0</div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className="bet-section" style={{ padding: "0px" }}>
        <div
          className="bet-section-main"
          style={{ borderRadius: "0px 0px 10px 10px" }}
        >
          <div
            className="d-flex align-center justify-between"
            style={{ flexDirection: "column" }}
          >
            <div className="bet-tabs">
              {bet_tabs.map((tab, index) => (
                <div
                  key={tab.label}
                  className={`d5-bet-tab ${
                    betActiveTab === tab.label ? "active" : ""
                  }`}
                  onClick={() => handleBetTabClick(tab.label)}
                >
                  {tab.label}
                </div>
              ))}
            </div>
            <div className="d5-bet-tab-content" style={{ width: "100%" }}>
              {betActiveTab === "Sum" ? (
                <div className="d5-tab-bet">
                  <div className="d-flex align-center justify-between">
                    <div
                      className={`d5-box-bet ${
                        activeBoxbet === "big" ? "active" : ""
                      }`}
                      onClick={() => handleActiveBoxbet("big")}
                    >
                      <span>Big</span>
                      <span>1.98</span>
                    </div>
                    <div
                      className={`d5-box-bet ${
                        activeBoxbet === "small" ? "active" : ""
                      }`}
                      onClick={() => handleActiveBoxbet("small")}
                    >
                      <span>Small</span>
                      <span>1.98</span>
                    </div>
                    <div
                      className={`d5-box-bet ${
                        activeBoxbet === "odd" ? "active" : ""
                      }`}
                      onClick={() => handleActiveBoxbet("odd")}
                    >
                      <span>Odd</span>
                      <span>1.98</span>
                    </div>
                    <div
                      className={`d5-box-bet ${
                        activeBoxbet === "even" ? "active" : ""
                      }`}
                      onClick={() => handleActiveBoxbet("even")}
                    >
                      <span>Even</span>
                      <span>1.98</span>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="d5-tab-bet">
                  <div className="d-flex align-center justify-between">
                    <div
                      className={`d5-box-bet ${
                        activeBoxbet === "big" ? "active" : ""
                      }`}
                      onClick={() => handleActiveBoxbet("big")}
                    >
                      <span>Big</span>
                      <span>1.98</span>
                    </div>
                    <div
                      className={`d5-box-bet ${
                        activeBoxbet === "small" ? "active" : ""
                      }`}
                      onClick={() => handleActiveBoxbet("small")}
                    >
                      <span>Small</span>
                      <span>1.98</span>
                    </div>
                    <div
                      className={`d5-box-bet ${
                        activeBoxbet === "odd" ? "active" : ""
                      }`}
                      onClick={() => handleActiveBoxbet("odd")}
                    >
                      <span>Odd</span>
                      <span>1.98</span>
                    </div>
                    <div
                      className={`d5-box-bet ${
                        activeBoxbet === "even" ? "active" : ""
                      }`}
                      onClick={() => handleActiveBoxbet("even")}
                    >
                      <span>Even</span>
                      <span>1.98</span>
                    </div>
                  </div>
                  <div
                    className="d-flex align-center justify-between"
                    style={{ padding: "20px 10px", flexWrap: "wrap" }}
                  >
                    {nums.map((n, i) => (
                      <div className="d5-num-container" key={i}>
                        <div
                          className={`d5-bet-circle ${
                            activeNumbers.includes(n) ? "active" : ""
                          }`}
                          onClick={() => handleActiveNumbers(n)}
                        >
                          {n}
                        </div>
                        <span style={{ fontSize: ".85rem", color: "#768096" }}>
                          9
                        </span>
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="bet-section-model" style={{ marginTop: "0px" }}>
          <div className="counter1">{seconds.charAt(0)}</div>
          <div className="counter2">{seconds.charAt(1)}</div>
        </div>
      </div>
      <div className="history-tabs">
        {tabs.map((tab, index) => (
          <div
            key={tab.label}
            className={`history-tab ${activeTab === tab.label ? "active" : ""}`}
            onClick={() => handleTabClick(tab.label)}
          >
            {tab.label}
          </div>
        ))}
      </div>
      <div className="history-tab-content">
        {tabs.find((tab) => tab.label === activeTab)?.content}
      </div>
      <div className={`off-canvas ${isOpen ? "open" : ""}`}>
        <div className="off-canvas-container">
          <div className="off-canvas-content">
            <div className="k3-model-content1">
              <div className="k3-content-1">
                <div className="bet-tabs">
                  {bet_tabs.map((tab, index) => (
                    <div
                      key={tab.label}
                      className={`d5-bet-tab ${
                        betActiveTab === tab.label ? "active" : ""
                      }`}
                      onClick={() => handleBetTabClick(tab.label)}
                    >
                      {tab.label}
                    </div>
                  ))}
                </div>
                <div className="d5-bet-tab-content" style={{ width: "100%" }}>
                  {betActiveTab === "Sum" ? (
                    <div className="d5-tab-bet">
                      <div className="d-flex align-center justify-between">
                        <div
                          className={`d5-box-bet ${
                            activeBoxbet === "big" ? "active" : ""
                          }`}
                          onClick={() => handleActiveBoxbet("big")}
                        >
                          <span>Big</span>
                          <span>1.98</span>
                        </div>
                        <div
                          className={`d5-box-bet ${
                            activeBoxbet === "small" ? "active" : ""
                          }`}
                          onClick={() => handleActiveBoxbet("small")}
                        >
                          <span>Small</span>
                          <span>1.98</span>
                        </div>
                        <div
                          className={`d5-box-bet ${
                            activeBoxbet === "odd" ? "active" : ""
                          }`}
                          onClick={() => handleActiveBoxbet("odd")}
                        >
                          <span>Odd</span>
                          <span>1.98</span>
                        </div>
                        <div
                          className={`d5-box-bet ${
                            activeBoxbet === "even" ? "active" : ""
                          }`}
                          onClick={() => handleActiveBoxbet("even")}
                        >
                          <span>Even</span>
                          <span>1.98</span>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="d5-tab-bet">
                      <div className="d-flex align-center justify-between">
                        <div
                          className={`d5-box-bet ${
                            activeBoxbet === "big" ? "active" : ""
                          }`}
                          onClick={() => handleActiveBoxbet("big")}
                        >
                          <span>Big</span>
                          <span>1.98</span>
                        </div>
                        <div
                          className={`d5-box-bet ${
                            activeBoxbet === "small" ? "active" : ""
                          }`}
                          onClick={() => handleActiveBoxbet("small")}
                        >
                          <span>Small</span>
                          <span>1.98</span>
                        </div>
                        <div
                          className={`d5-box-bet ${
                            activeBoxbet === "odd" ? "active" : ""
                          }`}
                          onClick={() => handleActiveBoxbet("odd")}
                        >
                          <span>Odd</span>
                          <span>1.98</span>
                        </div>
                        <div
                          className={`d5-box-bet ${
                            activeBoxbet === "even" ? "active" : ""
                          }`}
                          onClick={() => handleActiveBoxbet("even")}
                        >
                          <span>Even</span>
                          <span>1.98</span>
                        </div>
                      </div>
                      <div
                        className="d-flex align-center justify-between"
                        style={{ padding: "20px 10px", flexWrap: "wrap" }}
                      >
                        {nums.map((n, i) => (
                          <div className="d5-num-container" key={i}>
                            <div
                              className={`d5-bet-circle ${
                                activeNumbers.includes(n) ? "active" : ""
                              }`}
                              onClick={() => handleActiveNumbers(n)}
                            >
                              {n}
                            </div>
                            <span
                              style={{ fontSize: ".85rem", color: "#768096" }}
                            >
                              9
                            </span>
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="content2">
              <div className="content2-balance">
                <span>Balance</span>
                <div className="d-flex">
                  {[1, 10, 100, 1000].map((val) => (
                    <div
                      key={val}
                      className={`x-box ${
                        balance === val ? "active " + bg : ""
                      }`}
                      onClick={() => handleBalance(val)}
                    >
                      {val}
                    </div>
                  ))}
                </div>
              </div>
              <div className="content2-qty">
                <span>Quantity</span>
                <div className="d-flex">
                  <div
                    className={`x-box btn ${bg}`}
                    onClick={() => handleMlt(false)}
                  >
                    -
                  </div>
                  <div className="x-box mlt">
                    <input
                      type="number"
                      className="mlt-input"
                      value={mltValue}
                      readOnly={true}
                      onChange={handleInput}
                      min="1"
                      max="9999"
                    />
                  </div>
                  <div
                    className={`x-box btn ${bg}`}
                    onClick={() => handleMlt(true)}
                  >
                    +
                  </div>
                </div>
              </div>
              <div className="content2-mlt">
                <div className="d-flex">
                  {[1, 5, 10, 20, 50, 100].map((multiplier) => (
                    <div
                      key={multiplier}
                      className={`x-box ${
                        mltValue === multiplier ? "active " + bg : ""
                      }`}
                      onClick={() => handleMlt2(multiplier)}
                    >
                      X{multiplier}
                    </div>
                  ))}
                </div>
              </div>
              <div className="content2-policy">
                <div className="d-flex align-center" style={{ gap: "10px" }}>
                  <div
                    className="d-flex align-center"
                    style={{ gap: "10px" }}
                    onClick={handleAgree}
                  >
                    <img
                      src={agree ? checkImg : checkCircle}
                      alt=""
                      className="check-img"
                    />
                    <span>I agree</span>
                  </div>
                  <span
                    className="policy-btn"
                    onClick={() => {
                      $("#agreeModel").removeClass("hide");
                      $("#agreeModel").addClass("show");
                    }}
                  >
                    《Pre-sale rules》
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="off-canvas-footer">
            <div
              className="cancel"
              onClick={() => toggleOffCanvas(false, null, null)}
            >
              Cancel
            </div>
            <div className={`amt ${bg}`} onClick={confirmBet}>
              Total amount {formatMoney(betAmount)}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default D51;

// History Tab Component
const GameHistory = ({ triggerEffect }) => {
  const [wingoList, setWingoList] = useState([]);
  const [pageNo, setPageNo] = useState(0);
  const [pageCount, setPageCount] = useState(0);

  const fetchWingoList = async (page) => {
    try {
      const response = await getD5List(page, "10min");
      if (response.status === 200) {
        setWingoList(response.data.data);
        setPageCount(response.data.totalPageCount);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const nextPage = () => {
    if (pageCount === pageNo) {
      return;
    }
    setPageNo((prevPageNo) => prevPageNo + 1);
    fetchWingoList(pageNo + 1);
  };

  const prevPage = () => {
    if (pageNo <= 0) {
      return;
    }
    setPageNo((prevPageNo) => prevPageNo - 1);
    fetchWingoList(pageNo - 1);
  };

  useEffect(() => {
    fetchWingoList(0);
  }, [triggerEffect]);
  return (
    <>
      <div className="game-hsitory">
        <div className="game-history-header">
          <span>Period</span>
          <span>Results</span>
          <span>Total</span>
        </div>
        <div className="game-history-content">
          {wingoList.map((item, index) => (
            <div className="history-item" key={index}>
              <span>{item.period_id}</span>
              <div style={{ display: "flex", gap: "5px" }}>
                {item.multiple_num_result.split(",").map((item, index) => (
                  <div className="border-box" key={index}>
                    {item}
                  </div>
                ))}
              </div>
              <span>{item.num_result}</span>
            </div>
          ))}
        </div>
      </div>
      <div className="game-history-footer">
        <div
          className={`prev ${pageNo > 0 ? "active" : ""}`}
          onClick={() => prevPage()}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="25px"
            height="25px"
            viewBox="0 0 24 24"
          >
            <path
              fill="#768096"
              d="M13.83 19a1 1 0 0 1-.78-.37l-4.83-6a1 1 0 0 1 0-1.27l5-6a1 1 0 0 1 1.54 1.28L10.29 12l4.32 5.36a1 1 0 0 1-.78 1.64"
            />
          </svg>
        </div>
        <div className="pages">
          <span>{pageNo + 1}</span>/<span>{pageCount + 1}</span>
        </div>
        <div
          className={`next ${pageNo < pageCount ? "active" : ""}`}
          onClick={() => nextPage()}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="25px"
            height="25px"
            viewBox="0 0 24 24"
          >
            <path
              fill="#fff"
              d="M10 19a1 1 0 0 1-.64-.23a1 1 0 0 1-.13-1.41L13.71 12L9.39 6.63a1 1 0 0 1 .15-1.41a1 1 0 0 1 1.46.15l4.83 6a1 1 0 0 1 0 1.27l-5 6A1 1 0 0 1 10 19"
            />
          </svg>
        </div>
      </div>
    </>
  );
};

const Chart = ({ triggerEffect }) => {
  const canvasRef = useRef("null");
  const [wingoList, setWingoList] = useState([]);
  const [pageNo, setPageNo] = useState(0);
  const [pageCount, setPageCount] = useState(0);

  const ludoImages = [ludo1, ludo2, ludo3, ludo4, ludo5, ludo6];
  const fetchWingoList = async (page) => {
    try {
      const response = await getD5List(page, "10min");
      if (response.status === 200) {
        setWingoList(response.data.data);
        setPageCount(response.data.totalPageCount);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const nextPage = () => {
    if (pageCount === pageNo) {
      return;
    }
    setPageNo((prevPageNo) => prevPageNo + 1);
    fetchWingoList(pageNo + 1);
  };

  const prevPage = () => {
    if (pageNo <= 0) {
      return;
    }
    setPageNo((prevPageNo) => prevPageNo - 1);
    fetchWingoList(pageNo - 1);
  };

  useEffect(() => {
    fetchWingoList(0);
  }, [triggerEffect]);

  return (
    <>
      <div className="chart-history">
        <div className="chart-history-header">
          <span>Period</span>
          <span style={{ width: "30%" }}>Results</span>
          <span style={{ width: "30%" }}>Number</span>
        </div>
        <div className="chart-history-content"></div>
      </div>
      <div className="chart-history-footer">
        <div
          className={`prev ${pageNo > 0 ? "active" : ""}`}
          onClick={prevPage}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="25px"
            height="25px"
            viewBox="0 0 24 24"
          >
            <path
              fill="#768096"
              d="M13.83 19a1 1 0 0 1-.78-.37l-4.83-6a1 1 0 0 1 0-1.27l5-6a1 1 0 0 1 1.54 1.28L10.29 12l4.32 5.36a1 1 0 0 1-.78 1.64"
            />
          </svg>
        </div>
        <div className="pages">
          <span>{pageNo + 1}</span>/<span>{pageCount + 1}</span>
        </div>
        <div
          className={`next ${pageNo < pageCount ? "active" : ""}`}
          onClick={nextPage}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="25px"
            height="25px"
            viewBox="0 0 24 24"
          >
            <path
              fill="#fff"
              d="M10 19a1 1 0 0 1-.64-.23a1 1 0 0 1-.13-1.41L13.71 12L9.39 6.63a1 1 0 0 1 .15-1.41a1 1 0 0 1 1.46.15l4.83 6a1 1 0 0 1 0 1.27l-5 6A1 1 0 0 1 10 19"
            />
          </svg>
        </div>
      </div>
    </>
  );
};

const MyHistory = ({ triggerEffect }) => {
  const [history, setHistory] = useState([]);
  const [pageNo, setPageNo] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const fetchMyHistory = async (page) => {
    try {
      const response = await getUserBetHistory(page, "d5", "10min");
      if (response.status === 200) {
        setHistory(response.data.data);
        setPageCount(response.data.totalPageCount);
      }
    } catch (err) {
      console.log(err);
    }
  };
  const nextPage = () => {
    if (pageCount === pageNo) {
      return;
    }
    setPageNo((prevPageNo) => prevPageNo + 1);
    fetchMyHistory(pageNo + 1);
  };

  const prevPage = () => {
    if (pageNo <= 0) {
      return;
    }
    setPageNo((prevPageNo) => prevPageNo - 1);
    fetchMyHistory(pageNo - 1);
  };
  const [expandedIndex, setExpandedIndex] = useState(null);

  const handleToggle = (index) => {
    setExpandedIndex(expandedIndex === index ? null : index);
  };
  useEffect(() => {
    fetchMyHistory(0);
  }, [triggerEffect]);
  return (
    <>
      <div className="my-history">
        <div className="my-history-details">
          <div className="details-btn">
            Details
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20px"
              height="20px"
              viewBox="0 0 512 512"
            >
              <path
                fill="none"
                stroke="var(--main-color)"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="32"
                d="M262.62 336L342 256l-79.38-80m68.35 80H170"
              />
              <path
                fill="none"
                stroke="var(--main-color)"
                strokeMiterlimit="10"
                strokeWidth="32"
                d="M256 448c106 0 192-86 192-192S362 64 256 64S64 150 64 256s86 192 192 192Z"
              />
            </svg>
          </div>
        </div>
        <div className="my-history-content">
          {history.length > 0 ? (
            history.map((item, index) => (
              <div
                className="my-history-item"
                key={index}
                onClick={() => handleToggle(index)}
              >
                <div
                  className="d-flex align-center justify-between"
                  style={{ width: "100%", paddingBottom: "20px" }}
                >
                  <div className="d-flex align-center" style={{ gap: "10px" }}>
                    {isNaN(item.bet) ? (
                      <div className={`bet-span bg-${item.bet}`}>
                        {item.bet}
                      </div>
                    ) : (
                      <div
                        className={`bet-span bg-${
                          Number(item.bet) > 5 ? "red" : "green"
                        }`}
                      >
                        {""}
                      </div>
                    )}
                    <div
                      className="d-flex flex-col"
                      style={{ alignItems: "start", gap: "10px" }}
                    >
                      <span>{item.period_id}</span>
                      <span style={{ fontSize: ".8rem", color: "#768096" }}>
                        {formatDate("d-m-Y h:M:S A", item.order_time)}
                      </span>
                    </div>
                  </div>
                  <div className="win-status">
                    {item.win_status === "win" ? (
                      <>
                        <div
                          className="status-box"
                          style={{ color: "#00ae50", borderColor: "#00ae50" }}
                        >
                          {"Succeed"}
                        </div>
                        <div
                          className="result-amt"
                          style={{ color: "#00ae50" }}
                        >
                          +{formatMoney(item.result_amount)}
                        </div>
                      </>
                    ) : (
                      <>
                        {item.win_status === "loss" ? (
                          <>
                            <div
                              className="status-box"
                              style={{
                                color: "var(--main-color)",
                                borderColor: "var(--main-color)",
                              }}
                            >
                              {"Failed"}
                            </div>
                            <div
                              className="result-amt"
                              style={{ color: "var(--main-color)" }}
                            >
                              -{formatMoney(item.Amount_after_tax)}
                            </div>
                          </>
                        ) : (
                          ""
                        )}
                      </>
                    )}
                  </div>
                </div>
                <div
                  className={`history-details ${
                    expandedIndex === index ? "show" : ""
                  }`}
                >
                  <span style={{ fontSize: "1.2rem" }}>Details</span>
                  {Object.keys(item).map((key, i) => (
                    <div className="details-item" key={i}>
                      <span style={{ textTransform: "capitalize" }}>
                        {key.replace("_", " ")}
                      </span>
                      <span>
                        {key === "order_time"
                          ? formatDate("Y-m-d h:M:S A", item[key])
                          : item[key]}
                      </span>
                    </div>
                  ))}
                </div>
              </div>
            ))
          ) : (
            <div className="empty">
              <img src={noData} alt="" className="noData" />
              <span>No Data</span>
            </div>
          )}
        </div>
      </div>
      {history.length > 0 ? (
        <div className="my-history-footer">
          <div
            className={`prev ${pageNo > 0 ? "active" : ""}`}
            onClick={prevPage}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="25px"
              height="25px"
              viewBox="0 0 24 24"
            >
              <path
                fill="#768096"
                d="M13.83 19a1 1 0 0 1-.78-.37l-4.83-6a1 1 0 0 1 0-1.27l5-6a1 1 0 0 1 1.54 1.28L10.29 12l4.32 5.36a1 1 0 0 1-.78 1.64"
              />
            </svg>
          </div>
          <div className="pages">
            <span>{pageNo + 1}</span>/
            <span>{pageCount > 0 ? pageCount + 1 : 1}</span>
          </div>
          <div
            className={`next ${pageNo < pageCount ? "active" : ""}`}
            onClick={nextPage}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="25px"
              height="25px"
              viewBox="0 0 24 24"
            >
              <path
                fill="#fff"
                d="M10 19a1 1 0 0 1-.64-.23a1 1 0 0 1-.13-1.41L13.71 12L9.39 6.63a1 1 0 0 1 .15-1.41a1 1 0 0 1 1.46.15l4.83 6a1 1 0 0 1 0 1.27l-5 6A1 1 0 0 1 10 19"
              />
            </svg>
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
};
