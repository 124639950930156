import axios from "../api/axiosConfig";

export const Deposit = async (payload) => {
  const token = localStorage.getItem("token");
  const uid = localStorage.getItem("uid");
  payload.uid = uid;
  try {
    const response = await axios.post("/api/user/deposit", payload, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });
    return response.data;
  } catch (error) {
    console.error("Deposit error:", error);
  }
};

export const Checkout = async (payload) => {
  const token = localStorage.getItem("token");
  const uid = localStorage.getItem("uid");
  payload.uid = uid;
  try {
    const response = await axios.post("/api/user/checkout", payload, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });
    return response.data;
  } catch (error) {
    console.error("Deposit error:", error);
  }
};

export const Widthdraw = async (payload) => {
  const token = localStorage.getItem("token");
  const uid = localStorage.getItem("uid");
  payload.uid = uid;
  try {
    const response = await axios.post("/api/user/withdraw", payload, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });
    return response.data;
  } catch (error) {
    console.error("Deposit error:", error);
  }
};
export const RechargeNow = async (amount) => {
  const token = localStorage.getItem("token");
  const uid = localStorage.getItem("uid");
  const payload = {
    amount: amount,
    name: "ClubTata",
    description: "Recharge",
  };
  try {
    const response = await axios.post("/createOrder", payload, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });
    return response.data;
  } catch (error) {
    console.error("Deposit error:", error);
  }
};

export const ConfirmPayment = async (order_id, amount) => {
  const token = localStorage.getItem("token");
  const payload = {
    utr_no: order_id,
  };
  try {
    const response = await axios.post("/api/user/confirmPayment", payload, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });
    return response.data;
  } catch (error) {
    console.error("Deposit error:", error);
  }
};

export const CreatePayment = async (order_id, amount) => {
  const token = localStorage.getItem("token");
  const uid = localStorage.getItem("uid");
  const payload = {
    pay_type: "UPI",
    uid: uid,
    utr_no: order_id,
    amount: amount,
  };
  try {
    const response = await axios.post("/api/user/createPayment", payload, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });
    return response.data;
  } catch (error) {
    console.error("Deposit error:", error);
  }
};
