import React from "react";
import BottomNav from "../common/BottomNav";
import CustomHeader from "../common/CustomHeader";
import "../../assets/css/activity.css";
import {
  invitationBonus,
  bettingRebate,
  superJackpot,
  signInBanner,
  giftBanner,
  giftRedeem,
  banner4,
} from "../../assets/media";
import { Link, useNavigate } from "react-router-dom";

const Activity = () => {
  const navigate = useNavigate();

  const handleClick = (route) => {
    navigate(route);
  };

  return (
    <>
      <CustomHeader bg={"var(--main_gradient-color)"} logo={true} />
      <div className="info">
        <span className="info-heading">Activity</span>
        <span className="info-text" style={{ fontSize: ".85rem" }}>
          Please remember to follow the event page
        </span>
        <span className="info-text" style={{ fontSize: ".85rem" }}>
          We will launch user feedback activities from time to time
        </span>
      </div>
      <div className="activity-tabs">
        <div className="activity-tab">
          <Link to={"/activity/InvitationBonus"}>
            <img src={invitationBonus} alt="" />
          </Link>
          <span>Invitation bonus</span>
        </div>
        <div className="activity-tab">
          <Link to={"/activity/BettingRebet"}>
            <img src={bettingRebate} alt="" />
          </Link>
          <span>Betting rebate</span>
        </div>
        <div className="activity-tab">
          <Link to={"/activity/SuperJackpot"}>
            <img src={superJackpot} alt="" />
          </Link>
          <span>Super jackpot</span>
        </div>
      </div>
      <div className="card-tabs">
        <div
          className="card"
          onClick={() => handleClick("/activity/RedeemGift")}
        >
          <img src={signInBanner} alt="" />
          <div className="card-body">
            <div className="card-title">Gift</div>
            <div className="card-des">
              Enter the redemption code to receive gift rewards
            </div>
          </div>
        </div>
        <div
          className="card"
          onClick={() => handleClick("/activity/Attendance")}
        >
          <img src={giftRedeem} alt="" />
          <div className="card-body">
            <div className="card-title">Attendance bonus</div>
            <div className="card-des">
              The more consecutive days you sign in, the higher the reward will
              be.
            </div>
          </div>
        </div>
      </div>
      {/* <div className="card-banners">
        {[1].map((a, i) => (
          <Link to={"/activity/FirstDeposit"} key={i}>
            <div className="card-banner">
              <img src={banner4} alt="" />
              <div className="card-title">New Member First Deposit Bonus</div>
            </div>
          </Link>
        ))}
        <div className="no-more">No more</div>
      </div> */}
      <BottomNav />
    </>
  );
};

export default Activity;
