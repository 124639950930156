import axios from "../api/axiosConfig";

export const betWingo = async (type, user_id, bet, amount, period_id) => {
  const token = localStorage.getItem("token");

  try {
    const response = await axios.post(
      "/api/user/betWingo",
      {
        type,
        user_id,
        bet,
        amount,
        period_id,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );
    return response; // return response.data if you want only the data
  } catch (error) {
    console.error("BetWingo error:", error);
    throw error; // re-throw the error to handle it in the calling function
  }
};

export const betK3 = async (type, user_id, bet, amount, period_id) => {
  const token = localStorage.getItem("token");

  try {
    const response = await axios.post(
      "/api/user/betK3",
      {
        type,
        user_id,
        bet,
        amount,
        period_id,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );
    return response; // return response.data if you want only the data
  } catch (error) {
    console.error("BetWingo error:", error);
    throw error; // re-throw the error to handle it in the calling function
  }
};

export const betD5 = async (type, user_id, bet, amount, period_id) => {
  const token = localStorage.getItem("token");

  try {
    const response = await axios.post(
      "/api/user/betD5",
      {
        type,
        user_id,
        bet,
        amount,
        period_id,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );
    return response; // return response.data if you want only the data
  } catch (error) {
    console.error("BetWingo error:", error);
    throw error; // re-throw the error to handle it in the calling function
  }
};


export const betTrxWin = async (type, user_id, bet, amount, period_id) => {
  const token = localStorage.getItem("token");

  try {
    const response = await axios.post(
      "/api/user/betTrxWin",
      {
        type,
        user_id,
        bet,
        amount,
        period_id,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );
    return response; // return response.data if you want only the data
  } catch (error) {
    console.error("BetWingo error:", error);
    throw error; // re-throw the error to handle it in the calling function
  }
};
