import React, { useEffect, useRef, useState } from "react";
import "../../../assets/css/vip.css";
import $ from "jquery";
import CustomHeader from "../../common/CustomHeader";
import { getUser } from "../../../utils/UserController";
import {
  formatDate,
  formatMoney,
  getPercentage,
  getTabIndexByValue,
  showLoader,
} from "../../../utils/Utils";
import {
  av0,
  av1,
  vipbatch1,
  vipbatch2,
  vipbatch3,
  vipbatch4,
  vipbatch5,
  vipbatch6,
  vipbatch7,
  vipbatch8,
  vipbatch9,
  vipbatch10,
  vipCoin,
  vipCoins,
  vipDiamond,
  vipGift,
  vipcrown,
  vipcrowngold,
  unlocked,
  walletIcon,
  vip1,
  vip2,
  vip3,
  vip4,
  vip5,
  vip6,
  vip7,
  vip8,
  av11,
  av12,
  av13,
  av14,
  av15,
  av16,
  av17,
  av2,
  av3,
  av4,
  av5,
  av6,
  av7,
  av8,
  av9,
  av18,
  av19,
  av20,
  av10,
} from "../../../assets/media";
import { Link } from "react-router-dom";

export const Vip = () => {
  const tabsRef = useRef(null);
  const [activeTab, setActiveTab] = useState(1);
  const [level, setLevel] = useState(0);
  const tabs = [
    { value: 3000, reward: 60, monthly: 30 },
    { value: 30000, reward: 180, monthly: 90 },
    { value: 400000, reward: 690, monthly: 290 },
    { value: 4000000, reward: 1690, monthly: 690 },
    { value: 20000000, reward: 6900, monthly: 1690 },
    { value: 80000000, reward: 16900, monthly: 6900 },
    { value: 300000000, reward: 69000, monthly: 16900 },
    { value: 1000000000, reward: 169000, monthly: 69000 },
    { value: 5000000000, reward: 690000, monthly: 169000 },
    { value: 9999999999, reward: 1690000, monthly: 690000 },
  ];

  const avatars = [
    av1,
    av2,
    av3,
    av4,
    av5,
    av6,
    av7,
    av8,
    av9,
    av10,
    av11,
    av12,
    av13,
    av14,
    av15,
    av16,
    av17,
    av18,
    av19,
    av20,
  ];

  const batches = [
    vipbatch1,
    vipbatch2,
    vipbatch3,
    vipbatch4,
    vipbatch5,
    vipbatch6,
    vipbatch7,
    vipbatch8,
    vipbatch9,
    vipbatch10,
  ];

  const vips = [av0, vip1, vip2, vip3, vip4, vip5, vip6, vip7, vip8];

  const [user, setUser] = useState({});

  useEffect(() => {
    const handleScroll = () => {
      if (tabsRef.current) {
        const tabContainer = tabsRef.current;
        const containerWidth = tabContainer.offsetWidth;

        // Check which tab should be active
        let newActiveTab = activeTab;
        Array.from(tabContainer.children).forEach((tab, index) => {
          const tabRect = tab.getBoundingClientRect();
          const containerRect = tabContainer.getBoundingClientRect();

          const overlapWidth =
            Math.min(tabRect.right, containerRect.right) -
            Math.max(tabRect.left, containerRect.left);

          const tabVisiblePercentage = (overlapWidth / containerWidth) * 100;

          if (tabVisiblePercentage >= 60) {
            newActiveTab = index;
          }
        });

        if (newActiveTab !== activeTab) {
          setActiveTab(newActiveTab);
        }

        // Smoothly scroll to the new active tab
        const activeTabElement = tabContainer.children[newActiveTab];
        const tabElementWidth = activeTabElement.offsetWidth;
        const scrollPosition =
          activeTabElement.offsetLeft -
          (containerWidth / 2 - tabElementWidth / 2);
        tabContainer.scrollTo({
          left: scrollPosition,
          behavior: "smooth",
        });
      }
    };

    handleScroll();

    $(".vip-content-container").fadeOut();
    $(".vip-content-container").fadeIn(300);

    // Attach scroll event listener
    window.addEventListener("scroll", handleScroll);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [activeTab]);

  useEffect(() => {
    const getUserData = async () => {
      showLoader(true);
      try {
        const res = await getUser();
        setUser(res.data.data);
        setLevel(getTabIndexByValue(res.data.data.exp));
        
        showLoader(false);
      } catch (error) {
        console.log(error);
      }
    };
    getUserData();
  }, []);
  return (
    <div className="vip-container">
      <CustomHeader
        path={"/account"}
        heading={"VIP"}
        bg={"var(--main_gradient-color)"}
        color={"#fff"}
      />
      <div className="vip-banner"></div>
      <div className="vip-user-details-container">
        <div className="vip-user-details">
          <Link to={"/account/ChangeAvatar"}>
            <img src={avatars[user?.avatar]} alt="" />
          </Link>
          <div className="d-flex flex-col" style={{ padding: "5px 0px" }}>
            <div className="vip-name">
              <img src={vips[level]} alt="" />
              <span>{user?.name}</span>
            </div>
          </div>
        </div>
      </div>
      <div className="vip-divs">
        <div className="vip-div my-exp">
          <div
            className="d-flex align-center"
            style={{ gap: "5px", color: "var(--main-color)" }}
          >
            <span>{user?.exp}</span>
            <span>EXP</span>
          </div>
          <span className="bs-txt">My experience</span>
        </div>
        <div className="vip-div payout">
          <div className="d-flex align-center" style={{ gap: "5px" }}>
            <span
              style={{
                fontWeight: "bold",
                color: "var(--lightText)",
                fontSize: "1.2rem",
              }}
            >
              {user?.vip_days}
            </span>
            <span>Days</span>
          </div>
          <span className="bs-txt">Payout time</span>
        </div>
      </div>
      <div className="vip-time">
        VIP level rewards are settled at 2:00 am on the 1st of every month
      </div>
      <div className="vip-tabs-container" ref={tabsRef}>
        {tabs.map((tab, index) => (
          <div
            key={index}
            className={`vip-tab ${activeTab === index ? "active" : ""} vipbg${
              index + 1
            }`}
            onClick={() => setActiveTab(index)}
          >
            <div className="vip-batch-header">
              <div className="d-flex flex-col">
                <div className="vip-batch-detail">
                  <img src={index < 1 ? vipcrown : vipcrowngold} alt="" />
                  <h3 className={`${index < 1 ? "" : "goldtxt"}`}>
                    VIP{index + 1}
                  </h3>
                  <img src={unlocked} alt="" style={{ width: "15px" }} />
                  <p style={{ color: "#fff" }}>Not open yet</p>
                </div>
                <span className="rq">
                  Upgrading VIP {index + 1} reguires {tab.value}EXP
                </span>
                <div className="bet-batch">Bet ₹1=1EXP</div>
              </div>

              <img src={batches[index]} alt="" className="vip-batch" />
            </div>
            <div className={`${index < 1 ? "range" : "rangex"}`}>
              <div
                className="range-fill"
                style={{ width: `${getPercentage(tab.value, user?.exp)}%` }}
              ></div>
            </div>
            <div className="range-bottom">
              <div className="range-val">
                {user?.exp}/{tab.value}
              </div>
              <span>{tab.value} EXP can be leveled up</span>
            </div>
          </div>
        ))}
      </div>
      <div className="vip-content-container">
        <div className="vip-content-header">
          <img src={vipDiamond} alt="" />
          <h3>VIP {activeTab + 1} Benifit level</h3>
        </div>
        <div className="vip-content">
          <div className="item">
            <img src={vipGift} alt="" />
            <div
              className="d-flex flex-col justify-between"
              style={{ width: "100%", padding: "5px" }}
            >
              <div className="d-flex align-center justify-between">
                <span className="title">Level up rewards</span>
                <div className="gift-money">
                  <img src={walletIcon} alt="" style={{ width: "15px" }} />
                  <span style={{ fontSize: ".85rem" }}>
                    {formatMoney(tabs[activeTab].reward)}
                  </span>
                </div>
              </div>
              <div className="d-flex align-center justify-between">
                <span className="des">
                  Each account can receive only one time
                </span>
                <div
                  className="gift-money"
                  style={{
                    borderColor: "var(--main-color)",
                    padding: "1px 5px",
                    justifyContent: "center",
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="15px"
                    height="15px"
                    viewBox="0 0 20 20"
                  >
                    <path
                      fill="var(--main-color)"
                      d="M2 10a8 8 0 1 0 16 0a8 8 0 0 0-16 0m7.6-2.385L9.99 8l.4-.393a2.12 2.12 0 0 1 3.035 2.959l-3.06 3.273a.5.5 0 0 1-.73 0L6.57 10.561A2.113 2.113 0 0 1 9.6 7.615"
                    ></path>
                  </svg>
                  <span style={{ fontSize: ".85rem" }}>{0}</span>
                </div>
              </div>
            </div>
          </div>
          <div className="item">
            <img src={vipCoin} alt="" />
            <div
              className="d-flex flex-col justify-between"
              style={{ width: "100%", padding: "5px" }}
            >
              <div className="d-flex align-center justify-between">
                <span className="title">Monthly rewards</span>
                <div className="gift-money">
                  <img src={walletIcon} alt="" style={{ width: "15px" }} />
                  <span style={{ fontSize: ".85rem" }}>
                    {formatMoney(tabs[activeTab].monthly)}
                  </span>
                </div>
              </div>
              <div className="d-flex align-center justify-between">
                <span className="des">
                  Each account can receive only one time per month
                </span>
                <div
                  className="gift-money"
                  style={{
                    borderColor: "var(--main-color)",
                    padding: "1px 5px",
                    justifyContent: "center",
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="15px"
                    height="15px"
                    viewBox="0 0 20 20"
                  >
                    <path
                      fill="var(--main-color)"
                      d="M2 10a8 8 0 1 0 16 0a8 8 0 0 0-16 0m7.6-2.385L9.99 8l.4-.393a2.12 2.12 0 0 1 3.035 2.959l-3.06 3.273a.5.5 0 0 1-.73 0L6.57 10.561A2.113 2.113 0 0 1 9.6 7.615"
                    ></path>
                  </svg>
                  <span style={{ fontSize: ".85rem" }}>{0}</span>
                </div>
              </div>
            </div>
          </div>
          <div className="item">
            <img src={vipCoins} alt="" />
            <div
              className="d-flex flex-col justify-between"
              style={{ width: "100%", padding: "5px" }}
            >
              <div className="d-flex align-center justify-between">
                <span className="title">Rebate rate</span>
                <span>0.10</span>
              </div>
              <div className="d-flex align-center justify-between">
                <span className="des">Increase income of rebate</span>
                <span>0.360</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Vip;
