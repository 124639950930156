import React, { useEffect, useRef, useState } from "react";
import CustomHeader from "../../common/CustomHeader";
import {
  casino,
  lotteryLogo,
  slots,
  sports,
  ballNum0,
  ballNum1,
  ballNum2,
  ballNum3,
  ballNum4,
  ballNum5,
  ballNum6,
  ballNum7,
  ballNum8,
  ballNum9,
  noData,
} from "../../../assets/media";
import { getBetHistory } from "../../../utils/UserController";
import { formatDate, formatMoney } from "../../../utils/Utils";

const BetHistory = () => {
  const [bets, setBets] = useState([]);

  const tabs = [
    {
      image: lotteryLogo,
      label: "Lottery",
      component: <Lottery bets={bets} />,
    },
    {
      image: casino,
      label: "Casino",
      component: <Casino />,
    },
    {
      image: sports,
      label: "Rummy",
      component: <Rummy />,
    },
    {
      image: slots,
      label: "Slots",
      component: <Slots />,
    },
  ];

  const [activeTab, setActiveTab] = useState(0);
  const tabsRef = useRef(null);

  useEffect(() => {
    if (tabsRef.current) {
      const activeTabElement = tabsRef.current.children[activeTab];
      const tabContainerWidth = tabsRef.current.offsetWidth;
      const tabElementWidth = activeTabElement.offsetWidth;
      const scrollPosition =
        activeTabElement.offsetLeft -
        (tabContainerWidth / 2 - tabElementWidth / 2);
      tabsRef.current.scrollTo({
        left: scrollPosition,
        behavior: "smooth",
      });
    }

    const getBetHistoryData = async () => {
      try {
        const res = await getBetHistory();
        if (res.data.status) {
          setBets(res.data.data);
        }
      } catch (error) {}
    };

    getBetHistoryData();
  }, [activeTab]);
  return (
    <>
      <CustomHeader
        bg={"var(--themeBlack)"}
        color={"var(--mainText)"}
        heading={"Bet history"}
        path={"/account"}
      />
      <div className="games-tabs-container" ref={tabsRef}>
        {tabs.map((tab, index) => (
          <div
            key={index}
            className={`games-tab ${index === activeTab ? "active" : ""}`}
            onClick={() => setActiveTab(index)}
          >
            <img src={tab.image} alt="" />
            {tab.label}
          </div>
        ))}
      </div>
      <div className="games-tab-content">{tabs[activeTab].component}</div>
    </>
  );
};

export default BetHistory;

const Lottery = ({ bets }) => {
  const balls = [
    { src: ballNum0, bg: "bg-rv" },
    { src: ballNum1, bg: "bg-green" },
    { src: ballNum2, bg: "bg-red" },
    { src: ballNum3, bg: "bg-green" },
    { src: ballNum4, bg: "bg-red" },
    { src: ballNum5, bg: "bg-gv" },
    { src: ballNum6, bg: "bg-red" },
    { src: ballNum7, bg: "bg-green" },
    { src: ballNum8, bg: "bg-red" },
    { src: ballNum9, bg: "bg-green" },
  ];
  return (
    <>
      <div
        className="rebet-tab-content"
        style={{ background: "none", padding: "0" }}
      >
        {bets.length > 0 ? (
          bets.map((v, i) => (
            <div className="bet-history" key={i}>
              <div className="bet-history-top">
                <div className="d-flex align-center justify-between bb">
                  <div
                    className="d-flex align-start flex-col"
                    style={{ gap: "5px" }}
                  >
                    <span
                      style={{
                        fontSize: "1.1rem",
                        fontWeight: "bold",
                        textTransform: "capitalize",
                      }}
                    >
                      {v.game}
                    </span>
                    <span style={{ fontSize: ".75rem", color: "#768096c7" }}>
                      {formatDate("Y-m-d h:M A", v.created_at)}
                    </span>
                  </div>
                  <span
                    style={{
                      fontSize: "1rem",
                      color: v.win_status === "win" ? "#49ce9b" : "#e98613",
                      textTransform: "capitalize",
                    }}
                  >
                    {v.win_status}
                  </span>
                </div>
                <div className="d-row">
                  <span>Type</span>
                  <span style={{ textTransform: "capitalize" }}>
                    {v.game} {v.game_type}
                  </span>
                </div>
                <div className="d-row">
                  <span>Period</span>
                  <span>{v.period_id}</span>
                </div>
                <div className="d-row">
                  <span>Order number</span>
                  <span>{v.order_id}</span>
                </div>
                <div className="d-row">
                  <span>Select</span>
                  <span
                    style={{ color: "#768096c7", textTransform: "capitalize" }}
                  >
                    {v.bet}
                  </span>
                </div>
                <div className="d-row">
                  <span>Total bet</span>
                  <span>{formatMoney(v.purchase_amount)}</span>
                </div>
              </div>
              <div className="bet-history-mid"></div>
              <div className="bet-history-bot">
                <div
                  className="d-row"
                  style={{ fontSize: "1rem", marginBottom: "10px" }}
                >
                  Lottery Result
                </div>
                <div
                  className="d-row"
                  style={{ justifyContent: "start", gap: "10px" }}
                >
                  <img
                    src={`${balls[Number(v.result)]?.src}`}
                    alt=""
                    width={"25px"}
                  />
                  <div
                    className={`bs ${
                      Number(v.result) > 4 ? "bg-big" : "bg-small"
                    } `}
                  >
                    {v.result > 4 ? "Big" : "Small"}
                  </div>
                  <div className={`bs ${balls[Number(v.result)]?.bg} `}>
                    {Number(v.result) % 2 == 0 ? "Red" : "Green"}
                  </div>
                </div>
                <div className="amt-grid">
                  <div className="amt-col">
                    <span>{formatMoney(v.amount)}</span>
                    <span>Actual Amount</span>
                  </div>
                  <div className="amt-col">
                    <span>{formatMoney(v.result_amount)}</span>
                    <span>Winnings</span>
                  </div>
                  <div className="amt-col">
                    <span>{formatMoney(v.tax)}</span>
                    <span>Handling fee</span>
                  </div>
                  <div className="amt-col">
                    <span
                      className={`${
                        Number(v.result_amount) - Number(v.purchase_amount) < 0
                          ? "text-red"
                          : "text-green"
                      }`}
                    >
                      {Number(v.result_amount) - Number(v.purchase_amount) < 0
                        ? "-"
                        : "+"}
                      {formatMoney(
                        Number(v.result_amount) - Number(v.purchase_amount) * -1
                      )}
                    </span>
                    <span>Profit/Loss</span>
                  </div>
                </div>
              </div>
            </div>
          ))
        ) : (
          <div className="empty">
            <img src={noData} alt="" className="noData" />
            <span>No Data</span>
          </div>
        )}
      </div>
    </>
  );
};
const Casino = () => {
  const [isloading, setIsloading] = useState(true);
  setTimeout(() => {
    setIsloading(false);
  }, 1000);
  return (
    <>
      {isloading ? (
        <div className="server-loader"></div>
      ) : (
        <div className="empty">
          <img src={noData} alt="" className="noData" />
          <span>No Data</span>
        </div>
      )}
    </>
  );
};
const Rummy = () => {
  const [isloading, setIsloading] = useState(true);
  setTimeout(() => {
    setIsloading(false);
  }, 1000);
  return (
    <>
      {isloading ? (
        <div className="server-loader"></div>
      ) : (
        <div className="empty">
          <img src={noData} alt="" className="noData" />
          <span>No Data</span>
        </div>
      )}
    </>
  );
};
const Slots = () => {
  const [isloading, setIsloading] = useState(true);
  setTimeout(() => {
    setIsloading(false);
  }, 1000);
  return (
    <>
      {isloading ? (
        <div className="server-loader"></div>
      ) : (
        <div className="empty">
          <img src={noData} alt="" className="noData" />
          <span>No Data</span>
        </div>
      )}
    </>
  );
};
