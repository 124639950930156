import React, { useState, useEffect } from "react";
import { Navigate } from "react-router-dom";
import { getUser } from "./UserController";
import loadingMid from "../assets/logo/brand_logo.png";
import loadingRing from "../assets/img/spin_ring.png";
import $ from "jquery";

export const ProtectedRoute = ({ element: Component, ...rest }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [isToken, setIsToken] = useState(!!localStorage.getItem("token"));
  const [isExpired, setIsExpired] = useState(false);

  useEffect(() => {
    const checkUser = async () => {
      if (isToken) {
        const userData = await getUser();
        setIsExpired(userData.data.status === "expired");
      }
      setIsLoading(false);
    };

    checkUser();
  }, [isToken]);

  if (isLoading) {
    return (
      <div className="loader">
        <div className="loading-box">
          <img src={"/brand-logo.png"} alt="" className="loading-mid" />
          <img src={loadingRing} alt="" className="loading-ring amin-spin" />
        </div>
      </div>
    );
  }

  if (!isToken || isExpired) {
    return <Navigate to="/login" />;
  }

  return <Component {...rest} />;
};

export const LoginProtectedRoute = ({ element: Component, ...rest }) => {
  const isLoggedIn = !!localStorage.getItem("token");

  return isLoggedIn ? <Navigate to="/account" /> : <Navigate to="/login" />;
};
