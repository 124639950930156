import axios from "../api/axiosConfig";

const getToken = () => {
  return localStorage.getItem("token");
};

export const Register = async (phone, password, otp, invite_code) => {
  try {
    const response = await axios.post("/api/user/register", {
      phone,
      password,
      otp,
      invite_code,
      device: `${JSON.stringify(navigator.platform)} ${JSON.stringify(
        navigator.userAgent
      )}`,
    });
    return response;
  } catch (error) {
    console.error("Login error:", error);
  }
};

export const Login = async (phone, password) => {
  try {
    const response = await axios.post("/api/user/login", {
      phone,
      password,
      device: `${JSON.stringify(navigator.platform)} ${JSON.stringify(
        navigator.userAgent
      )}`,
    });
    return response;
  } catch (error) {
    console.error("Login error:", error);
  }
};

export const Logout = () => {
  localStorage.removeItem("token");
  localStorage.removeItem("uid");
  return true;
};

export const getUser = async () => {
  const token = getToken();
  const uid = localStorage.getItem("uid");

  try {
    const response = await axios.get("/api/user/getUser", {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      params: {
        uid: uid,
      },
    });

    return response;
  } catch (error) {
    console.error("Login error:", error);
  }
};

export const getUserBetHistory = async (page, game, type) => {
  const token = getToken();
  const uid = localStorage.getItem("uid");
  try {
    const response = await axios.get("/api/user/getUserBetHistory", {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      params: {
        uid: uid,
        page: page,
        game: game,
        type: type,
      },
    });

    return response;
  } catch (error) {
    console.error("Login error:", error);
  }
};

export const getUserWinStatus = async (game, type) => {
  const token = getToken();
  const uid = localStorage.getItem("uid");
  try {
    const response = await axios.get("/api/user/getUserWinStatus", {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      params: {
        uid: uid,
        game: game,
        type: type,
      },
    });

    return response;
  } catch (error) {
    console.error("Login error:", error);
  }
};

export const addUserBank = async (bankData) => {
  const token = getToken();
  const uid = localStorage.getItem("uid");
  bankData.uid = uid;
  try {
    const response = await axios.post("/api/user/addUserBank", bankData, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });
    return response;
  } catch (error) {
    console.error("Error:", error);
  }
};

export const getUserBank = async () => {
  const token = getToken();
  const uid = localStorage.getItem("uid");
  try {
    const response = await axios.get("/api/user/getUserBank", {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      params: {
        uid: uid,
      },
    });
    return response;
  } catch (error) {
    console.error("Error:", error);
  }
};

export const changeUserBank = async (bankid) => {
  const token = getToken();
  const uid = localStorage.getItem("uid");
  try {
    const response = await axios.get("/api/user/changeUserBank", {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      params: {
        uid: uid,
        bankid: bankid,
      },
    });
    return response;
  } catch (error) {
    console.error("Error:", error);
  }
};
export const changeUserAvatar = async (avid) => {
  const token = getToken();
  const uid = localStorage.getItem("uid");
  try {
    const response = await axios.get("/api/user/changeUserAvatar", {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      params: {
        uid: uid,
        avid: avid,
      },
    });
    return response;
  } catch (error) {
    console.error("Error:", error);
  }
};
export const changeUserName = async (name) => {
  const token = getToken();
  const uid = localStorage.getItem("uid");
  try {
    const response = await axios.get("/api/user/changeUserName", {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      params: {
        uid: uid,
        name: name,
      },
    });
    return response;
  } catch (error) {
    console.error("Error:", error);
  }
};

export const getLoginList = async () => {
  const token = localStorage.getItem("token");
  const uid = localStorage.getItem("uid");

  const response = await axios.get("/api/user/getLoginList", {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
    params: {
      uid: uid,
    },
  });
  return response;
};

export const getDepositList = async (type, status, date) => {
  const token = localStorage.getItem("token");
  const uid = localStorage.getItem("uid");
  const pay_type = type;

  const response = await axios.get("/api/user/getDepositList", {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
    params: {
      uid: uid,
      pay_type: pay_type,
      status: status,
      date: date,
    },
  });
  return response;
};

export const getWithdrawalList = async (type, status, date) => {
  const token = localStorage.getItem("token");
  const uid = localStorage.getItem("uid");
  const pay_type = type;

  const response = await axios.get("/api/user/getWithdrawalList", {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
    params: {
      uid: uid,
      pay_type: pay_type,
      status: status,
      date: date,
    },
  });
  return response;
};

export const getOtp = async (number) => {
  const token = localStorage.getItem("token");
  const uid = localStorage.getItem("uid");

  const response = await axios.get("/api/user/getOtp", {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
    params: {
      uid: uid,
      number: number,
    },
  });
  return response;
};

export const redeemGiftCode = async (gift_code) => {
  const token = getToken();
  const uid = localStorage.getItem("uid");
  try {
    const response = await axios.post(
      "/api/user/redeemGiftCode",
      {
        uid: uid,
        gift_code: gift_code,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );
    return response;
  } catch (error) {
    console.error("Error:", error);
  }
};

export const getRedeemHistory = async () => {
  const token = getToken();
  const uid = localStorage.getItem("uid");
  const response = await axios.get("/api/user/getRedeemHistory", {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
    params: {
      uid: uid,
    },
  });
  return response;
};

export const makeAttendance = async () => {
  const token = getToken();
  const uid = localStorage.getItem("uid");
  try {
    const response = await axios.post(
      "/api/user/makeAttendance",
      {
        uid: uid,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );
    return response;
  } catch (error) {
    console.error("Error:", error);
  }
};

export const getPromotion = async () => {
  const token = getToken();
  const uid = localStorage.getItem("uid");
  try {
    const response = await axios.get("/api/user/getPromotion", {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      params: {
        uid: uid,
      },
    });
    return response;
  } catch (error) {
    console.error("Error:", error);
  }
};
export const getTransactionHistory = async () => {
  const token = getToken();
  const uid = localStorage.getItem("uid");
  try {
    const response = await axios.get("/api/user/getTransactionHistory", {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      params: {
        uid: uid,
      },
    });
    return response;
  } catch (error) {
    console.error("Error:", error);
  }
};
export const getInvitationList = async () => {
  const token = getToken();
  const uid = localStorage.getItem("uid");
  try {
    const response = await axios.get("/api/user/getInvitationList", {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      params: {
        uid: uid,
      },
    });
    return response;
  } catch (error) {
    console.error("Error:", error);
  }
};

export const getInviteBonus = async (people, amount) => {
  const token = getToken();
  const uid = localStorage.getItem("uid");
  try {
    const response = await axios.get("/api/user/getInviteBonus", {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      params: {
        uid: uid,
        people: people,
        amount: amount,
      },
    });
    return response;
  } catch (error) {
    console.error("Error:", error);
  }
};
export const getInviteBonusList = async () => {
  const token = getToken();
  const uid = localStorage.getItem("uid");
  try {
    const response = await axios.get("/api/user/getInviteBonusList", {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      params: {
        uid: uid,
      },
    });
    return response;
  } catch (error) {
    console.error("Error:", error);
  }
};
export const getIssues = async () => {
  const token = getToken();
  const uid = localStorage.getItem("uid");
  try {
    const response = await axios.get("/api/user/getIssues", {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      params: {
        uid: uid,
      },
    });
    return response;
  } catch (error) {
    console.error("Error:", error);
  }
};

export const getNews = async () => {
  const token = getToken();
  const uid = localStorage.getItem("uid");
  try {
    const response = await axios.get("/api/user/getNews", {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      params: {
        uid: uid,
      },
    });
    return response;
  } catch (error) {
    console.error("Error:", error);
  }
};

export const claimFD = async () => {
  const token = getToken();
  const uid = localStorage.getItem("uid");
  try {
    const response = await axios.get("/api/user/claimFD", {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      params: {
        uid: uid,
      },
    });
    return response;
  } catch (error) {
    console.error("Error:", error);
  }
};
export const getBetHistory = async () => {
  const token = getToken();
  const uid = localStorage.getItem("uid");
  try {
    const response = await axios.get("/api/user/getBetHistory", {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      params: {
        uid: uid,
      },
    });
    return response;
  } catch (error) {
    console.error("Error:", error);
  }
};

export const changePassword = async (data) => {
  const token = getToken();
  const uid = localStorage.getItem("uid");
  data.uid = uid;
  try {
    const response = await axios.post("/api/user/changePassword", data, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });
    return response;
  } catch (error) {
    console.error("Error:", error);
  }
};

export const submitIssue = async (data) => {
  const token = getToken();
  try {
    const response = await axios.post("/api/user/issues", data, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
        "Content-Type": "multipart/form-data",
      },
    });
    return response;
  } catch (error) {
    console.error("Error:", error);
  }
};
